import React, { useState, useEffect } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import classnames from "classnames";
import { Button, Tooltip } from "neetoui";
import usersApi from "apis/organization_users";
import commissionApi from "apis/commissions";
import EmptyData from "../../Common/EmptyData";
import UserPane from "./UsersPane";

const OrganizationUsersTable = () => {
  const [userData, setUserData] = useState([]);
  const [showUserPane, setShowUserPane] = useState(false);
  const [userId, setUserId] = useState(null);
  const [commissionData, setCommissionData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(null);

  const { organizationId } = useParams();

  const history = useHistory();

  useEffect(() => {
    fetchUsersData();
    fetchCommissionData();
  }, [pageIndex]);

  const fetchCommissionData = async () => {
    try {
      const response = await commissionApi.fetch(organizationId);
      setCommissionData(response.data.commissions);
    } catch (error) {
      logger.error(error);
    }
  };

  const fetchUsersData = async () => {
    const payload = {
      params: {
        page: pageIndex,
        page_size: pageSize,
      },
    };
    try {
      const response = await usersApi.fetch(organizationId, payload);
      setUserData(response.data.users);
      setTotalCount(response.data.total_count);
    } catch (error) {
      logger.error(error);
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);
  const pageIndexEnds = pageIndex * pageSize;
  const pageIndexStarts = pageIndexEnds - pageSize;

  const getNextIndex = function () {
    return pageIndex < totalPages ? pageIndex + 1 : pageIndex;
  };

  const getPrevIndex = function () {
    return pageIndex > 1 ? pageIndex - 1 : pageIndex;
  };

  const nextPage = function () {
    setPageIndex(getNextIndex());
  };

  const previousPage = function () {
    setPageIndex(getPrevIndex());
  };

  const renderUserRole = userRole => {
    switch (userRole) {
      case "admin":
        return "Admin";
      case "sub_admin":
        return "Sub Admin";
      case "caregiver":
        return "Caregiver";
      default:
        return "Standard";
    }
  };
  if (userData.length == 0) {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-row justify-between items-center">
              <h1 className="flex flex-row justify-center items-center text-xl lg:text-2xl font-semibold text-gray-900">
                <span
                  onClick={() => {
                    history.push(`/organizations`);
                  }}
                  className="cursor-pointer text-gray-600 hover:text-gray-700"
                >
                  Organizations
                </span>
                <i className="ri-arrow-right-s-line"></i>
                <p>Users</p>
              </h1>
              <Button
                style="primary"
                label="New User"
                icon="ri-add-line ri-lg"
                onClick={() => {
                  setShowUserPane(true);
                }}
              />
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-6 md:px-8">
              <div className="py-4">
                <EmptyData message="No Users found for this Organization" />
              </div>
            </div>
          </div>
        </main>
        {showUserPane && (
          <UserPane
            isOpen={showUserPane}
            onClose={() => {
              setUserId(null);
              setShowUserPane(false);
            }}
            userId={userId}
            organizationId={organizationId}
            commissionData={commissionData}
            fetchUsersData={fetchUsersData}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center">
              <h1 className="flex flex-row justify-center items-center text-2xl font-semibold text-gray-900 mb-4 sm:mb-0">
                <span
                  onClick={() => {
                    history.push(`/organizations`);
                  }}
                  className="cursor-pointer text-gray-600 hover:text-gray-700"
                >
                  Organizations
                </span>
                <i className="ri-arrow-right-s-line"></i>
                <p>Users</p>
              </h1>
              <Button
                style="primary"
                label="New User"
                icon="ri-add-line ri-lg"
                onClick={() => setShowUserPane(true)}
              />
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-6 md:px-8">
              <div className="py-4">
                <div className="flex flex-col mt-6">
                  <div className="-my-2 overflow-x-auto lg:-mx-6 md:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full lg:px-6 md:px-8">
                      <div
                        className={classnames("shadow overflow-hidden", {
                          "lg:rounded-t-lg": totalCount > pageSize,
                          "lg:rounded-lg border-b border-gray-200":
                            totalCount <= pageSize,
                        })}
                      >
                        <table className="mgl-table min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                First Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Last Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Role
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {userData &&
                              userData.map((user, index) => (
                                <tr
                                  className={classnames({
                                    "bg-white": index % 2 == 0,
                                    "bg-gray-100": index % 2 == 1,
                                  })}
                                  key={user.id}
                                >
                                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {user.first_name}
                                  </td>
                                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {user.last_name}
                                  </td>
                                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                    {user.email}
                                  </td>
                                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                    {renderUserRole(user.role)}
                                  </td>
                                  <td className="px-6 py-2 whitespace-nowrap">
                                    <span
                                      className={classnames(
                                        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                                        {
                                          "bg-green-100 text-green-600":
                                            user.is_active === true,
                                          "bg-red-100 text-red-600":
                                            user.is_active === false,
                                        }
                                      )}
                                    >
                                      {user.is_active ? "Active " : "Inactive"}
                                    </span>
                                  </td>
                                  <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                    {user.created_at}
                                  </td>
                                  <td className="flex flex-row justify-end items-center px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                                    <Tooltip content="Edit">
                                      <Button
                                        style="icon"
                                        icon="ri-pencil-line"
                                        onClick={() => {
                                          setUserId(user.id);
                                          setShowUserPane(true);
                                        }}
                                      />
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize && (
                        <nav
                          className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg shadow"
                          aria-label="Pagination"
                        >
                          <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                              Showing{" "}
                              {totalCount < pageIndexEnds ? (
                                <span className="font-medium">
                                  {pageIndexStarts + 1} - {totalCount} of{" "}
                                  {totalCount}
                                </span>
                              ) : (
                                <span className="font-medium">
                                  {pageIndexStarts + 1} - {pageIndexEnds} of{" "}
                                  {totalCount}
                                </span>
                              )}{" "}
                              results
                            </p>
                          </div>
                          <div className="flex-1 flex justify-between sm:justify-end">
                            <Button
                              style="secondary"
                              className={classnames({
                                "cursor-not-allowed": pageIndex === 1,
                              })}
                              label="Previous"
                              onClick={() => previousPage()}
                            />
                            <Button
                              style="secondary"
                              className={classnames("ml-3", {
                                "cursor-not-allowed": totalPages === pageIndex,
                              })}
                              label="Next"
                              onClick={() => nextPage()}
                            />
                          </div>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {showUserPane && (
          <UserPane
            isOpen={showUserPane}
            onClose={() => {
              setUserId(null);
              setShowUserPane(false);
            }}
            userId={userId}
            organizationId={organizationId}
            commissionData={commissionData}
            fetchUsersData={fetchUsersData}
          />
        )}
      </>
    );
  }
};

export default withRouter(OrganizationUsersTable);
