import axios from "axios";

const fetch = (id, payload) =>
  axios.get(`api/v1/organizations/${id}/referrals`, payload);

const create = (id, payload) =>
  axios.post(`api/v1/organizations/${id}/referrals`, payload);

const update = (orgId, id, payload) =>
  axios.put(`api/v1/organizations/${orgId}/referrals/${id}`, payload);

const destroy = (orgId, id) =>
  axios.delete(`api/v1/organizations/${orgId}/referrals/${id}`);

const view = (id, payload) =>
  axios.get(`api/v1/organizations/${id}/referrals/view`, payload);

const fetchReferral = (orgId, id) =>
  axios.get(`api/v1/organizations/${orgId}/referrals/${id}`);

const createOrgReferral = payload =>
  axios.post(`api/v1/organizations/referral`, payload);

const referralApi = {
  fetch,
  create,
  update,
  destroy,
  view,
  fetchReferral,
  createOrgReferral,
};

export default referralApi;
