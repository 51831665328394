import React from "react";
import { withRouter } from "react-router-dom";
import OrganizationForm from "./OrganizationForm";

const Create = props => {
  const { fetchOrganizations, initialFormValues, onCancel } = props;

  return (
    <OrganizationForm
      initialFormValues={initialFormValues}
      fetchOrganizations={fetchOrganizations}
      onCancel={onCancel}
    />
  );
};

export default withRouter(Create);
