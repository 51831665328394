import React, { useState } from "react";
import { Pane } from "neetoui";
import Create from "./Create";
import Edit from "./Edit";
import { INITIAL_FORM_VALUES } from "../constants";

const CommissionPane = props => {
  const {
    isOpen,
    onClose,
    commissionId,
    organizationId,
    fetchCommissionData,
  } = props;
  const [initialFormValues, setInitialFormValues] = useState(
    INITIAL_FORM_VALUES
  );

  return (
    <Pane
      title={commissionId ? "Edit Commissions" : "Configure Commissions"}
      isOpen={isOpen}
      onClose={onClose}
    >
      {commissionId ? (
        <Edit
          commissionId={commissionId}
          organizationId={organizationId}
          fetchCommissionData={fetchCommissionData}
          onCancel={onClose}
        />
      ) : (
        <Create
          initialFormValues={initialFormValues}
          organizationId={organizationId}
          fetchCommissionData={fetchCommissionData}
          onCancel={onClose}
        />
      )}
    </Pane>
  );
};

export default CommissionPane;
