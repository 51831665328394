import React, { useState } from "react";
import { Button, Input, Select } from "neetoui";
import commissionApi from "apis/commissions";
import { USER_TYPES, LEVEL_TYPES } from "../constants";

const CommissionForm = props => {
  const {
    commissionId,
    organizationId,
    initialFormValues,
    fetchCommissionData,
    onCancel,
  } = props;
  const [formValues, setFormValues] = useState(initialFormValues);

  const handleSubmit = async () => {
    try {
      const payload = {
        commission: {
          ...formValues,
          value: Number(formValues.value),
        },
      };
      await commissionApi.create(organizationId, payload);
      onCancel();
      fetchCommissionData();
    } catch (err) {
      logger.error(err);
    }
  };

  const handleUpdate = async () => {
    try {
      const payload = {
        commission: {
          user_type: formValues.user_type,
          level: formValues.level,
          value: Number(formValues.value),
        },
      };
      await commissionApi.update(organizationId, commissionId, payload);
      onCancel();
      fetchCommissionData();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <>
      <div className="px-6 pb-20">
        <div className="mb-6">
          <Select
            label="User Type"
            name="user_type"
            value={USER_TYPES.find(obj => obj.value === formValues.user_type)}
            onChange={selected => {
              setFormValues({ ...formValues, user_type: selected.value });
            }}
            options={USER_TYPES}
          />
        </div>
        <div className="mb-6">
          <Select
            label="User Level"
            name="level"
            value={LEVEL_TYPES.find(obj => obj.value === formValues.level)}
            onChange={selected => {
              setFormValues({ ...formValues, level: selected.value });
            }}
            options={LEVEL_TYPES}
            className="mb-6"
          />
        </div>
        <Input
          label="Commission Amount"
          name="value"
          type="number"
          value={formValues.value}
          onChange={e =>
            setFormValues({
              ...formValues,
              value: e.target.value,
            })
          }
        />
      </div>
      <div className="nui-pane__footer nui-pane__footer--absolute">
        <Button
          onClick={onCancel}
          label="Cancel"
          size="large"
          style="secondary"
        />
        <Button
          onClick={commissionId ? handleUpdate : handleSubmit}
          className="ml-2"
          type="submit"
          label={commissionId ? "Update" : "Submit"}
          size="large"
          style="primary"
        />
      </div>
    </>
  );
};

export default CommissionForm;
