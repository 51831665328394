export const INITIAL_FORM_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  date_of_birth: new Date(),
  appointment: "",
  tcpa_consent: true,
};

export const INITIAL_FORM_VALUES_PUBLIC = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  date_of_birth: new Date(),
  appointment: "",
  referred_by: "",
  tcpa_consent: true,
};

export const REFERRAL_STATUS_TYPES = [
  { value: "approved", label: "Approved" },
  { value: "pending", label: "Pending" },
  { value: "rejected", label: "Rejected" },
];

export const FILTER_FORM_VALUES = {
  start_date: "",
  end_date: "",
};

export const EARNING_VIEW_TYPES = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];
