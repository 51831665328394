import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { PageLoader } from "neetoui";
import usersApi from "apis/organization_users";
import UserForm from "./UserForm";

const Edit = props => {
  const {
    userId,
    organizationId,
    commissionData,
    fetchUsersData,
    onCancel,
  } = props;

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    try {
      setLoading(true);
      const response = await usersApi.fetchUser(organizationId, userId);
      setUsers(response.data.user);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <UserForm
        userId={userId}
        organizationId={organizationId}
        fetchUsersData={fetchUsersData}
        commissionData={commissionData}
        initialFormValues={users}
        onCancel={onCancel}
      />
    );
  }
};

export default withRouter(Edit);
