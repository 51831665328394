import React, { useState, useEffect } from "react";
import { Button, PageLoader } from "neetoui";
import organizationApi from "apis/organization";
import OrganizationTable from "./OrganizationTable";
import OrganizationPane from "./Pane";
import EmptyData from "../../Common/EmptyData";

export default function index() {
  const [showPane, setShowPane] = useState(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(null);

  useEffect(() => {
    fetchOrganizations();
  }, [pageIndex]);

  const fetchOrganizations = async () => {
    const payload = {
      params: {
        page: pageIndex,
        page_size: pageSize,
      },
    };
    try {
      setLoading(true);
      const response = await organizationApi.fetch(payload);
      setOrganizations(response.data.organizations);
      setTotalCount(response.data.total_count);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start sm:flex-row sm:justify-between sm:items-center">
              <h1 className="text-2xl font-semibold text-gray-900 mb-4 sm:mb-0">
                Organizations
              </h1>
              <Button
                style="primary"
                label="New Organization"
                icon="ri-add-line ri-lg"
                onClick={() => setShowPane(true)}
              />
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-6 md:px-8">
              <div className="py-4">
                {organizations.length > 0 ? (
                  <OrganizationTable
                    organizations={organizations}
                    setOrganizationId={setOrganizationId}
                    setShowPane={setShowPane}
                    fetchOrganizations={fetchOrganizations}
                    totalCount={totalCount}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    setPageIndex={setPageIndex}
                  />
                ) : (
                  <EmptyData message="No Organizations Found" />
                )}
              </div>
            </div>
          </div>
        </main>
        {showPane && (
          <OrganizationPane
            organizationId={organizationId}
            isOpen={showPane}
            onClose={() => {
              setOrganizationId(null);
              setShowPane(false);
            }}
            fetchOrganizations={fetchOrganizations}
          />
        )}
      </>
    );
  }
}
