import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Input } from "neetoui";
import { Toastr } from "neetoui";
import queryString from "query-string";
import authenticationApi from "apis/authentication";
import Logo from "assets/images/logo.svg";
import BannerLogin from "assets/images/login_banner.jpg";

const ChangePassword = () => {
  const token = queryString.parse(location.search).token;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  const resetPassword = async () => {
    const payload = {
      user: {
        reset_password_token: token,
        password: newPassword,
        password_confirmation: confirmPassword,
      },
    };
    try {
      const response = await authenticationApi.resetPassword(payload);
      if (response.data.notice) {
        Toastr.success(response.data.notice);
      } else if (response.status == 204) {
        Toastr.success("Password has been updated successfully.");
      }
      history.push("/login");
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="text-center sm:text-left">
            <img
              className="h-16 w-auto m-auto sm:m-0"
              src={Logo}
              alt="Medigap Referral Network"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Reset Password
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form className="space-y-6">
                <Input
                  required
                  type="password"
                  value={newPassword}
                  label="Password"
                  onChange={e => setNewPassword(e.target.value)}
                />
                <Input
                  required
                  type="password"
                  value={confirmPassword}
                  label="Confirm Password"
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <div>
                  <button
                    onClick={e => {
                      e.preventDefault();
                      resetPassword();
                    }}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={BannerLogin}
          alt="Medicare Referral Network"
        />
      </div>
    </div>
  );
};

export default ChangePassword;
