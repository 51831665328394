import axios from "axios";

const fetch = () => axios.get("api/v1/users/details");
const fetchUser = id => axios.get(`api/v1/users/${id}`);
const update = (id, payload) => axios.put(`api/v1/users/${id}`, payload);

const userApi = {
  fetch,
  fetchUser,
  update,
};

export default userApi;
