import React, { useState } from "react";
import { Pane } from "neetoui";
import { INITIAL_FORM_VALUES } from "../constants";
import Create from "./Create";
import Edit from "./Edit";

const UserPane = props => {
  const {
    isOpen,
    onClose,
    userId,
    commissionData,
    organizationId,
    fetchUsersData,
  } = props;
  const [initialFormValues, setInitialFormValues] = useState(
    INITIAL_FORM_VALUES
  );

  return (
    <Pane
      title={userId ? "Edit User" : "Create User"}
      isOpen={isOpen}
      onClose={onClose}
      enforceFocus={false}
    >
      {userId ? (
        <Edit
          userId={userId}
          organizationId={organizationId}
          commissionData={commissionData}
          fetchUsersData={fetchUsersData}
          onCancel={onClose}
        />
      ) : (
        <Create
          organizationId={organizationId}
          commissionData={commissionData}
          fetchUsersData={fetchUsersData}
          initialFormValues={initialFormValues}
          onCancel={onClose}
        />
      )}
    </Pane>
  );
};

export default UserPane;
