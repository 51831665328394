import React from "react";
import { Link, useLocation } from "react-router-dom";

const SuperAdminNav = () => {
  const location = useLocation();
  return (
    <nav className="mt-8 flex-1 px-2 bg-white space-y-1">
      <Link
        to="/dashboard"
        className={
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-100 hover:no-underline " +
          (location.pathname.includes("/dashboard")
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900")
        }
      >
        <i
          className={
            "ri-dashboard-line ri-lg mr-3 " +
            (location.pathname.includes("/dashboard")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500")
          }
        ></i>
        Dashboard
      </Link>
      <Link
        to="/organizations"
        className={
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-100 hover:no-underline " +
          (location.pathname.includes("/organizations")
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900")
        }
      >
        <i
          className={
            "ri-building-line ri-lg mr-3 " +
            (location.pathname.includes("/organizations")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500")
          }
        ></i>
        Organizations
      </Link>
      <Link
        to="/organization/summary"
        className={
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-100 hover:no-underline " +
          (location.pathname.includes("/organization/summary")
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900")
        }
      >
        <i
          className={
            "ri-building-2-line ri-lg mr-3 " +
            (location.pathname.includes("/organization/summary")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500")
          }
        ></i>
        Organization Summary
      </Link>
    </nav>
  );
};

export default SuperAdminNav;
