import axios from "axios";

const fetchReferral = payload =>
  axios.get("api/v1/dashboard/referrals/summary", payload);
const fetchReferralEarnings = payload =>
  axios.get("api/v1/dashboard/referral_earnings/summary", payload);
const fetchOrganization = payload =>
  axios.get("api/v1/dashboard/organizations/summary", payload);
const fetchOrganizationSummary = payload =>
  axios.get("api/v1/dashboard/organizations", payload);
const fetchOrganizationReport = payload =>
  axios.get("api/v1/dashboard/organizations/report", payload);

const dashboardApi = {
  fetchReferral,
  fetchReferralEarnings,
  fetchOrganization,
  fetchOrganizationSummary,
  fetchOrganizationReport,
};

export default dashboardApi;
