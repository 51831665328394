import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "neetoui";

import authenticationApi from "apis/authentication";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";
import Logo from "assets/images/logo.svg";
import BannerLogin from "assets/images/login_banner.jpg";

const Signup = ({ history }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);

  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      const {
        data: { user, auth_token },
      } = await authenticationApi.signup({
        user: {
          email,
          first_name: firstName,
          last_name: lastName,
          password: password,
          password_confirmation: passwordConfirmation,
        },
      });
      authDispatch({
        type: "LOGIN",
        payload: { auth_token, email, is_admin: false },
      });
      userDispatch({ type: "SET_USER", payload: { user } });
      history.push("/");
    } catch (error) {
      alert(error.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="text-center sm:text-left">
            <img
              className="h-16 w-auto m-auto sm:m-0"
              src={Logo}
              alt="Medigap Referral Network"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <Input
                  required
                  aria-required="true"
                  type="email"
                  label="Email"
                  placeholder="oliver@example.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  name="email"
                  id="user_email"
                />
                <div className="space-y-1">
                  <Input
                    required
                    aria-required="true"
                    type="text"
                    label="First name"
                    placeholder="Sam"
                    name="first_name"
                    id="user_first_name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <Input
                    required
                    aria-required="true"
                    type="text"
                    name="last_name"
                    placeholder="Smith"
                    label="Last name"
                    id="user_last_name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <Input
                    required
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="******"
                    id="user_password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <Input
                    required
                    type="password"
                    label="Confirm password"
                    placeholder="******"
                    name="password_confirmation"
                    id="user_password_confirmation"
                    value={passwordConfirmation}
                    onChange={e => setPasswordConfirmation(e.target.value)}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Button
                    style="link"
                    label="Already have an account?"
                    to="/login"
                  />
                  <Button
                    style="link"
                    label="Forgot your password?"
                    to="/my/password/new"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Create an Account
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={BannerLogin}
          alt="Medicare Referral Network"
        />
      </div>
    </div>
  );
};

Signup.propTypes = {
  history: PropTypes.object,
};

export default Signup;
