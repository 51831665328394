import React, { useState } from "react";
import classnames from "classnames";
import { Button, Tooltip } from "neetoui";
import referralApi from "apis/referrals";
import ReferralModal from "./ReferralDataModal";

const index = ({
  user,
  organizationId,
  referralData,
  fetchReferralData,
  totalCount,
  pageIndex,
  pageSize,
  setPageIndex,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [referralId, setReferralId] = useState(true);
  const totalPages = Math.ceil(totalCount / pageSize);
  const pageIndexEnds = pageIndex * pageSize;
  const pageIndexStarts = pageIndexEnds - pageSize;

  const getNextIndex = function () {
    return pageIndex < totalPages ? pageIndex + 1 : pageIndex;
  };

  const getPrevIndex = function () {
    return pageIndex > 1 ? pageIndex - 1 : pageIndex;
  };

  const nextPage = function () {
    setPageIndex(getNextIndex());
  };

  const previousPage = function () {
    setPageIndex(getPrevIndex());
  };

  const handleDelete = async referralId => {
    try {
      await referralApi.destroy(organizationId, referralId);
      fetchReferralData();
    } catch (error) {
      logger.error(error);
    }
  };

  const renderUserRole = userRole => {
    switch (userRole) {
      case "super_admin":
        return "Super Admin";
      case "admin":
        return "Admin";
      case "sub_admin":
        return "Sub Admin";
      case "caregiver":
        return "Caregiver";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="flex flex-col mt-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={classnames("shadow overflow-hidden", {
                "rounded-t-lg": totalCount > pageSize,
                "rounded-lg border-b border-gray-200": totalCount <= pageSize,
              })}
            >
              <table className="mgl-table min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="pl-6 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone Number
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    {(user === "admin" || user === "sub_admin") && (
                      <>
                        <th
                          scope="col"
                          className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Referred By
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Role
                        </th>
                      </>
                    )}
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="pl-2 pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {referralData &&
                    referralData.map((referral, index) => (
                      <tr
                        className={classnames({
                          "bg-white": index % 2 == 0,
                          "bg-gray-100": index % 2 == 1,
                        })}
                        key={referral.id}
                        onClick={() => {
                          setReferralId(referral.id);
                          setShowModal(true);
                        }}
                      >
                        <td className="pl-6 pr-2 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">
                          {referral.full_name}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                          {referral.phone_number}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                          {referral.email}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap">
                          <span
                            className={classnames(
                              "px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize",
                              {
                                "bg-green-100 text-green-600":
                                  referral.status === "approved",
                                "bg-red-100 text-red-600":
                                  referral.status === "rejected",
                                "bg-yellow-100 text-yellow-600":
                                  referral.status === "pending",
                              }
                            )}
                          >
                            {referral.status}
                          </span>
                        </td>
                        {(user === "admin" || user === "sub_admin") && (
                          <>
                            <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500 capitalize">
                              {referral.referred_by}
                            </td>
                            <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                              {renderUserRole(referral.role)}
                            </td>
                          </>
                        )}
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
                          {referral.created_at}
                        </td>
                        <td className="flex flex-row justify-end items-center pl-2 pr-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                          <Tooltip content="View Referral">
                            <Button
                              className="mr-3"
                              style="icon"
                              icon="ri-user-line"
                              onClick={e => {
                                e.stopPropagation();
                                setReferralId(referral.id);
                                setShowModal(true);
                              }}
                            />
                          </Tooltip>
                          <Tooltip content="Delete">
                            <Button
                              style="icon"
                              icon="ri-delete-bin-line"
                              onClick={e => {
                                e.stopPropagation();
                                handleDelete(referral.id);
                              }}
                            />
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {totalCount > pageSize && (
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg shadow"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    {totalCount < pageIndexEnds ? (
                      <span className="font-medium">
                        {pageIndexStarts + 1} - {totalCount} of {totalCount}
                      </span>
                    ) : (
                      <span className="font-medium">
                        {pageIndexStarts + 1} - {pageIndexEnds} of {totalCount}
                      </span>
                    )}{" "}
                    results
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  <Button
                    style="secondary"
                    className={classnames({
                      "cursor-not-allowed": pageIndex === 1,
                    })}
                    label="Previous"
                    onClick={() => previousPage()}
                  />
                  <Button
                    style="secondary"
                    className={classnames("ml-3", {
                      "cursor-not-allowed": totalPages === pageIndex,
                    })}
                    label="Next"
                    onClick={() => nextPage()}
                  />
                </div>
              </nav>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <ReferralModal
          referralId={referralId}
          showModal={showModal}
          onModalCancel={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default index;
