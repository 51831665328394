import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Input, Select, Switch, DateInput } from "neetoui";
import usersApi from "apis/organization_users";
import { USER_TYPES } from "../constants";

const UserForm = props => {
  const {
    userId,
    organizationId,
    initialFormValues,
    commissionData,
    fetchUsersData,
    onCancel,
  } = props;
  const [formValues, setFormValues] = useState(initialFormValues);
  const [userType, setUserType] = useState(null);
  const [userCommissionLevel, setUserCommissionLevel] = useState([]);
  const [sendEmail, setSendEmail] = useState(true);
  const commissionLevel = [];

  const returnCommissionLevel = level => {
    switch (level) {
      case "level_1":
        return "Level 1";
      case "level_2":
        return "Level 2";
      case "level_3":
        return "Level 3";
    }
  };

  const returnUserType = type => {
    switch (type) {
      case "admin":
        setUserType("admin");
        break;
      case "sub_admin":
        setUserType("admin");
        break;
      case "caregiver":
        setUserType("caregiver");
        break;
    }
  };

  useEffect(() => {
    if (userType) {
      commissionData
        .filter(commissions => commissions.user_type === userType)
        .map(commission =>
          commissionLevel.push({
            value: commission.id,
            label: returnCommissionLevel(commission.level),
          })
        );
      setUserCommissionLevel(commissionLevel);
    }
  }, [userType]);

  const handleSubmit = async () => {
    try {
      const payload = {
        user: {
          ...formValues,
          send_email: sendEmail,
        },
      };
      await usersApi.create(organizationId, payload);
      onCancel();
      fetchUsersData();
    } catch (err) {
      logger.error(err);
    }
  };

  const handleUpdate = async () => {
    try {
      const payload = {
        user: {
          first_name: formValues.first_name,
          last_name: formValues.last_name,
          is_active: formValues.is_active,
          role: formValues.role,
          commission_id: formValues.commission_id,
        },
      };
      await usersApi.update(organizationId, userId, payload);
      onCancel();
      fetchUsersData();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <>
      <div className="px-6 pb-20">
        <div className="grid grid-cols-2 gap-4 mb-6">
          <Input
            label="First Name"
            name="first_name"
            type="text"
            value={formValues.first_name}
            onChange={e =>
              setFormValues({
                ...formValues,
                first_name: e.target.value,
              })
            }
          />
          <Input
            label="Last Name"
            name="last_name"
            type="text"
            value={formValues.last_name}
            onChange={e =>
              setFormValues({
                ...formValues,
                last_name: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-6">
          <Input
            label="Email Address"
            name="email"
            type="email"
            value={formValues.email}
            onChange={e =>
              setFormValues({
                ...formValues,
                email: e.target.value,
              })
            }
          />
        </div>
        {!userId && (
          <div className="mb-6">
            <Input
              label="Password"
              name="password"
              type="password"
              value={formValues.password}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  password: e.target.value,
                })
              }
              required
            />
          </div>
        )}
        <div className="mb-6">
          <Input
            label="Phone Number"
            name="phone_number"
            type="tel"
            value={formValues.phone_number}
            onChange={e =>
              setFormValues({
                ...formValues,
                phone_number: e.target.value,
              })
            }
            required
          />
        </div>
        <div className="mb-6">
          <Input
            label="Address"
            name="address"
            type="text"
            value={formValues.address}
            onChange={e =>
              setFormValues({
                ...formValues,
                address: e.target.value,
              })
            }
            required
          />
        </div>
        <div className="mb-6 grid grid-cols-2 gap-4">
          <Input
            label="City"
            name="city"
            type="text"
            value={formValues.city}
            onChange={e =>
              setFormValues({
                ...formValues,
                city: e.target.value,
              })
            }
            required
          />
          <Input
            label="State"
            name="state"
            type="text"
            value={formValues.state}
            onChange={e =>
              setFormValues({
                ...formValues,
                state: e.target.value,
              })
            }
            required
          />
        </div>
        <div className="mb-6 grid grid-cols-2 gap-4">
          <Input
            label="Zip Code"
            name="zip_code"
            type="text"
            value={formValues.zip_code}
            onChange={e =>
              setFormValues({
                ...formValues,
                zip_code: e.target.value,
              })
            }
            required
          />
          <DateInput
            name="date_of_birth"
            type="date"
            required={true}
            value={new Date(formValues.date_of_birth)}
            label="Date of Birth"
            format={"DD/MM/YYYY"}
            placeholder="DD/MM/YYYY"
            minDate={moment("1940-01-01").toDate()}
            maxDate={moment().toDate()}
            onChange={date =>
              setFormValues({
                ...formValues,
                date_of_birth: date,
              })
            }
          />
        </div>
        <Select
          label="User Type"
          name="level"
          value={USER_TYPES.find(obj => obj.value === formValues.role)}
          onChange={selected => {
            setFormValues({ ...formValues, role: selected.value });
            returnUserType(selected.value);
          }}
          options={USER_TYPES}
          className="mb-6"
        />
        {userType && (
          <Select
            label="Commission Level"
            name="commission_id"
            value={userCommissionLevel.find(
              obj => obj.value === formValues.commmission_id
            )}
            onChange={selected => {
              setFormValues({ ...formValues, commission_id: selected.value });
            }}
            options={userCommissionLevel}
            className="mb-6"
          />
        )}
        <div className="flex flex-row justify-start items-center w-full mb-6">
          <label className="mr-4">User Status</label>
          <Switch
            checked={formValues.is_active}
            onChange={() =>
              setFormValues({
                ...formValues,
                is_active: !formValues.is_active,
              })
            }
          />
        </div>
        {!userId && (
          <div className="flex flex-row justify-start items-center w-full mb-6">
            <label className="mr-4">Send Login details to User</label>
            <Switch
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
            />
          </div>
        )}
      </div>
      <div className="nui-pane__footer nui-pane__footer--absolute">
        <Button
          onClick={onCancel}
          label="Cancel"
          size="large"
          style="secondary"
        />
        <Button
          onClick={userId ? handleUpdate : handleSubmit}
          className="ml-2"
          type="submit"
          label={userId ? "Update" : "Submit"}
          size="large"
          style="primary"
        />
      </div>
    </>
  );
};

export default UserForm;
