import React, { useEffect, useState } from "react";
import { Modal } from "neetoui";
import referralApi from "apis/referrals";

const ReferralDataModal = ({ referralId, onModalCancel, showModal }) => {
  const [organizationId, setOrganizationId] = useState(
    localStorage.getItem("userOrganizationId")
  );
  const [loading, setLoading] = useState(false);
  const [referralData, setReferralData] = useState([]);

  useEffect(() => {
    fetchReferralData();
  }, []);

  const fetchReferralData = async () => {
    try {
      setLoading(true);
      const response = await referralApi.fetchReferral(
        organizationId,
        referralId
      );
      setReferralData(response.data.referral);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      isOpen={showModal}
      onClose={() => onModalCancel()}
      size="small"
      showCloseButton={true}
      autoHeight
    >
      <Modal.Title>Referral Details</Modal.Title>
      <div className="pt-1 flex flex-col items-start justify-start w-full">
        <div className="p-2">
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>Referral ID:</b> {referralData.id}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>First Name:</b> {referralData.first_name}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>Last Name:</b> {referralData.last_name}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>Phone Number:</b> {referralData.phone_number}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>Address:</b> {referralData.address}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>State:</b> {referralData.state}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>City:</b> {referralData.city}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>Zip Code:</b> {referralData.zip_code}
          </p>
          <p className="whitespace-nowrap text-sm text-gray-500 mb-2">
            <b>Date of Birth:</b> {referralData.date_of_birth}
          </p>
          {referralData.appointment && (
            <p className="whitespace-nowrap text-sm text-gray-500">
              <b>Appointment Date: </b>
              {referralData.appointment}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReferralDataModal;
