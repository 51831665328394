import React, { useState, useEffect } from "react";
import { Avatar, Dropdown } from "neetoui";
import { NavLink } from "react-router-dom";
import { useUserState } from "contexts/user";

export default function AccountDropdown({ userRole, handleLogout }) {
  const { user } = useUserState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const contact = user
    ? { name: `${user.first_name} ${user.last_name}` }
    : null;

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
    }
  }, [user]);

  const renderUserRole = userRole => {
    switch (userRole) {
      case "super_admin":
        return "Super Admin";
      case "admin":
        return "Admin";
      case "sub_admin":
        return "Sub Admin";
      case "caregiver":
        return "Caregiver";
      default:
        return "Standard User";
    }
  };

  return (
    <Dropdown
      className="z-50"
      position="right-top"
      interactionKind="hover"
      customTarget={() => (
        <div className="flex flex-row justify-start items-center cursor-pointer">
          <Avatar size={36} contact={contact} />
          <div className="flex flex-row justify-between items-center w-36 sm:w-44">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-700 leading-4">
                {firstName} {lastName}
              </p>
              <p className="text-xs font-medium text-gray-500">
                {renderUserRole(userRole)}
              </p>
            </div>
            <i className="ri-arrow-right-s-line ri-lg"></i>
          </div>
        </div>
      )}
      closeOnSelect
    >
      <div>
        <NavLink
          to="/profile"
          className="w-full nui-dropdown--item"
          activeClassName="active"
        >
          <span>My profile</span>
        </NavLink>
        <NavLink
          to="/password/edit"
          className="w-full nui-dropdown--item"
          activeClassName="active"
        >
          <span>Change password</span>
        </NavLink>
        <div onClick={handleLogout} className="w-full nui-dropdown--item">
          Logout
        </div>
      </div>
    </Dropdown>
  );
}
