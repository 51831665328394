import React, { useState, useEffect } from "react";
import { Button, PageLoader } from "neetoui";
import usersApi from "apis/organization_users";
import commissionApi from "apis/commissions";
import UsersTable from "./UsersTable";
import UserPane from "./Pane";
import EmptyData from "../../Common/EmptyData";

const index = () => {
  const [showPane, setShowPane] = useState(false);
  const [userId, setUserId] = useState(null);
  const organizationId = localStorage.getItem("userOrganizationId");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [commissionData, setCommissionData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(null);

  useEffect(() => {
    fetchUsersData();
    fetchCommissionData();
  }, [pageIndex]);

  const fetchCommissionData = async () => {
    try {
      const response = await commissionApi.fetch(organizationId);
      setCommissionData(response.data.commissions);
    } catch (error) {
      logger.error(error);
    }
  };

  const fetchUsersData = async () => {
    const payload = {
      params: {
        page: pageIndex,
        page_size: pageSize,
      },
    };
    try {
      setLoading(true);
      const response = await usersApi.fetch(organizationId, payload);
      setUserData(response.data.users);
      setTotalCount(response.data.total_count);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-row justify-between items-center">
              <h1 className="text-2xl font-semibold text-gray-900">Users</h1>
              <Button
                style="primary"
                label="New User"
                icon="ri-add-line ri-lg"
                onClick={() => setShowPane(true)}
              />
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-6 md:px-8">
              <div className="py-4">
                {userData.length > 0 ? (
                  <UsersTable
                    userData={userData}
                    setShowPane={setShowPane}
                    setUserId={setUserId}
                    totalCount={totalCount}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    setPageIndex={setPageIndex}
                  />
                ) : (
                  <EmptyData message="No Users Data Found" />
                )}
              </div>
            </div>
          </div>
        </main>
        {showPane && (
          <UserPane
            isOpen={showPane}
            onClose={() => {
              setUserId(null);
              setShowPane(false);
            }}
            userId={userId}
            organizationId={organizationId}
            commissionData={commissionData}
            fetchUsersData={fetchUsersData}
          />
        )}
      </>
    );
  }
};

export default index;
