import React, { useState, useEffect } from "react";
import { Button, PageLoader, Tooltip } from "neetoui";
import commissionApi from "apis/commissions";
import organizationApi from "apis/organization";
import OrganizationSettingsTable from "./OrganizationSettingsTable";
import CommissionPane from "./Pane";
import OrganizationPane from "./OrganizationPane";
import EmptyData from "../../Common/EmptyData";

export default function index() {
  const [showPane, setShowPane] = useState(false);
  const [organizationId, setOrganizationId] = useState(
    localStorage.getItem("userOrganizationId")
  );
  const [loading, setLoading] = useState(false);
  const [commmissionId, setCommmissionId] = useState(null);
  const [commissionData, setCommissionData] = useState([]);
  const [organizationnData, setOrganizationData] = useState([]);
  const [organizationPane, setOrganizationPane] = useState(false);
  const urlPath = window.location.origin;

  useEffect(() => {
    fetchCommissionData();
    fetchOrganizationData();
  }, []);

  const fetchOrganizationData = async () => {
    try {
      const response = await organizationApi.fetchOrganization(organizationId);
      setOrganizationData(response.data.organization);
    } catch (error) {
      logger.error(error);
    }
  };

  const fetchCommissionData = async () => {
    try {
      setLoading(true);
      const response = await commissionApi.fetch(organizationId);
      setCommissionData(response.data.commissions);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formUrl =
    urlPath + `/referral-form?ref=` + organizationnData?.referral_key;

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-row justify-between items-start">
              <div>
                <h1 className="text-2xl font-semibold text-gray-900 mb-8">
                  Settings
                </h1>
              </div>
              <Button
                style="primary"
                label="New Commission"
                icon="ri-add-line ri-lg"
                onClick={() => setShowPane(true)}
              />
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-8">
              <h2 className="text-xl font-semibold text-gray-700 mb-4">
                Basic Details
              </h2>
              <div className="w-full flex flex-col justify-center lg:flex-row lg:justify-between items-stretch">
                <div className="relative w-full lg:w-1/2 px-4 py-6 mb-4 lg:mb-8 mr-0 lg:mr-3 shadow rounded-lg bg-white border-b border-gray-200 lg:px-6">
                  <h3 className="text-base leading-6 font-medium text-gray-900 mb-4">
                    Organization Name
                  </h3>
                  <h3 className="text-lg leading-6 font-semibold text-gray-900">
                    {organizationnData.name}
                  </h3>
                  <Button
                    className="absolute top-4 right-4"
                    style="icon"
                    icon="ri-pencil-line"
                    onClick={() => {
                      setOrganizationPane(true);
                    }}
                  />
                </div>
                <div className="w-full lg:w-1/2 px-4 py-6 mb-8 shadow rounded-lg bg-white border-b border-gray-200 lg:px-6">
                  <h3 className="text-base leading-6 font-medium text-gray-900 mb-4">
                    Public Referral Form Url
                  </h3>
                  <a
                    href={formUrl}
                    target="_blank"
                    className="lg:rounded-md text-blue-600 leading-4"
                  >
                    {formUrl}
                  </a>
                </div>
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-6 md:px-8">
              <h2 className="text-xl font-semibold text-gray-700">
                Commission Settings
              </h2>
              <div className="py-2">
                {commissionData.length > 0 ? (
                  <OrganizationSettingsTable
                    organizationId={organizationId}
                    commissionData={commissionData}
                    setShowPane={setShowPane}
                    setCommmissionId={setCommmissionId}
                    fetchCommissionData={fetchCommissionData}
                  />
                ) : (
                  <EmptyData message="No Commission Data Found" />
                )}
              </div>
            </div>
          </div>
        </main>
        {showPane && (
          <CommissionPane
            isOpen={showPane}
            onClose={() => {
              setCommmissionId(null);
              setShowPane(false);
            }}
            organizationId={organizationId}
            commissionId={commmissionId}
            fetchCommissionData={fetchCommissionData}
          />
        )}
        {organizationPane && (
          <OrganizationPane
            isOpen={organizationPane}
            onClose={() => {
              setOrganizationPane(false);
            }}
            organizationId={organizationId}
            fetchOrganizationData={fetchOrganizationData}
          />
        )}
      </>
    );
  }
}
