import axios from "axios";

const fetch = (id, payload) =>
  axios.get(`api/v1/organizations/${id}/referral_earnings`, payload);

const view = (id, payload) =>
  axios.get(`api/v1/organizations/${id}/referral_earnings/view`, payload);

const earningsApi = {
  fetch,
  view,
};

export default earningsApi;
