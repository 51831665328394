import React, { useEffect, useState } from "react";
import commissionApi from "apis/commissions";
import CommissionForm from "./CommissionForm";
import { PageLoader } from "neetoui";

const Edit = props => {
  const { organizationId, commissionId, fetchCommissionData, onCancel } = props;

  const [commission, setCommission] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCommissionData();
  }, []);

  const loadCommissionData = async () => {
    try {
      setLoading(true);
      const response = await commissionApi.fetchCommission(
        organizationId,
        commissionId
      );
      setCommission(response.data.commission);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <CommissionForm
        organizationId={organizationId}
        commissionId={commissionId}
        initialFormValues={commission}
        fetchCommissionData={fetchCommissionData}
        onCancel={onCancel}
      />
    );
  }
};

export default Edit;
