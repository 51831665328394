import axios from "axios";

const fetch = payload => axios.get("api/v1/organizations", payload);

const create = payload => axios.post("api/v1/organizations", payload);

const destroy = id => axios.delete(`api/v1/organizations/${id}`);

const update = (id, payload) =>
  axios.put(`api/v1/organizations/${id}`, payload);

const fetchOrganization = id => axios.get(`api/v1/organizations/${id}`);

const organizationApi = {
  fetch,
  create,
  destroy,
  update,
  fetchOrganization,
};

export default organizationApi;
