import React, { useState, useEffect } from "react";
import { PageLoader, Select, Button, DateRangeInput } from "neetoui";
import moment from "moment";
import classnames from "classnames";
import { EARNING_VIEW_TYPES, INITIAL_FORM_VALUES } from "./constants";
import earningsApi from "apis/earnings";
import EarningsTable from "./EarningsTable";
import ViewTable from "./ViewTable";
import EmptyData from "../../Common/EmptyData";

export default function index() {
  const [organizationId, setOrganizationId] = useState(
    localStorage.getItem("userOrganizationId")
  );
  const [loading, setLoading] = useState(false);
  const [earningsData, setEarningsData] = useState([]);
  const [viewType, setViewType] = useState(null);
  const [totalAmount, setTotalAmount] = useState("");
  const [filterValues, setFilterValues] = useState(INITIAL_FORM_VALUES);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [referralCount, setReferralCount] = useState(null);

  useEffect(() => {
    fetchEarningsData();
  }, [pageIndex]);

  const fetchEarningsData = async () => {
    const payload = {
      params: {
        page: pageIndex,
        page_size: pageSize,
        scope: "all",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await earningsApi.fetch(organizationId, payload);
      setEarningsData(response.data.referral_earnings);
      setReferralCount(response.data.referrals_count);
      setTotalAmount(response.data.total_amount);
      setTotalCount(response.data.total_count);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const viewEarningsData = async viewType => {
    const payload = {
      params: {
        view: viewType,
        scope: "all",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await earningsApi.view(organizationId, payload);
      setEarningsData(response.data.referral_earnings);
      setTotalAmount(response.data.total_amount);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center">
              <h1 className="flex flex-row justify-center items-center text-2xl font-semibold text-gray-900 mb-4 lg:mb-0">
                <span
                  onClick={() => {
                    setViewType(null);
                    fetchEarningsData();
                  }}
                  className={classnames("cursor-pointer ", {
                    "text-gray-600 hover:text-gray-700": viewType,
                  })}
                >
                  Referral Earnings
                </span>
                {viewType && (
                  <>
                    <i className="ri-arrow-right-s-line mr-2"></i>
                    <span className="capitalize">{viewType} View</span>
                  </>
                )}
              </h1>
              <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-end lg:items-end">
                <DateRangeInput
                  className="mb-4 lg:mb-0"
                  value={dateRange}
                  onChange={selected => {
                    setDateRange(selected);
                    setFilterValues({
                      start_date: selected[0],
                      end_date: selected[1],
                    });
                  }}
                  format="DD-MM-YYYY"
                  minDate={moment("2010-01-01").toDate()}
                  maxDate={moment().toDate()}
                />
                <div className="grid grid-cols-2 gap-4 ml-0 lg:ml-3 w-full lg:w-64">
                  <Select
                    name="view"
                    placeholder="View"
                    value={EARNING_VIEW_TYPES.find(
                      obj => obj.value === viewType
                    )}
                    onChange={selected => {
                      setViewType(selected.value);
                      viewEarningsData(selected.value);
                    }}
                    options={EARNING_VIEW_TYPES}
                  />
                  <Button
                    style="primary"
                    icon="ri-filter-line ri-lg"
                    label="Filter"
                    onClick={() => {
                      if (viewType === null) {
                        fetchEarningsData();
                      } else {
                        viewEarningsData(viewType);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-6 md:px-8">
              <div className="mt-8 shadow rounded-lg bg-white px-4 py-3 border-b border-gray-200 lg:px-6 flex flex-col lg:flex-row justify-between items-center">
                <div className="w-full lg:w-1/2 flex flex-row justify-between items-center mr-0 mb-3 lg:mb-0 lg:mr-4 border-b lg:border-b-0 border-gray-400 lg:border-r lg:border-gray-400 pb-3 lg:pb-0 lg:pr-4 pr-0">
                  <h3 className="text-base leading-6 font-medium text-gray-900">
                    Total Amount Earned
                  </h3>
                  <h3 className="text-lg leading-6 font-semibold text-gray-900">
                    ${totalAmount}
                  </h3>
                </div>
                <div className="w-full lg:w-1/2 flex flex-row justify-between items-center">
                  <h3 className="text-base leading-6 font-medium text-gray-900">
                    Referrals Count
                  </h3>
                  <h3 className="text-lg leading-6 font-semibold text-gray-900">
                    {referralCount}
                  </h3>
                </div>
              </div>
              <div className="py-4">
                {earningsData.length > 0 ? (
                  viewType ? (
                    <ViewTable
                      viewType={viewType}
                      earningsData={earningsData}
                    />
                  ) : (
                    <EarningsTable
                      earningsData={earningsData}
                      totalCount={totalCount}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      setPageIndex={setPageIndex}
                    />
                  )
                ) : (
                  <EmptyData message="No Earnings Data Found" />
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
