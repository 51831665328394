import React from "react";
import classnames from "classnames";

const index = ({ viewType, earningsData }) => {
  const renderHeader = type => {
    switch (type) {
      case "daily":
        return "Date";
      case "weekly":
        return "Start Date";
      case "monthly":
        return "Month";
    }
  };
  return (
    <div className="flex flex-col mt-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {renderHeader(viewType)}
                  </th>
                  {viewType === "weekly" && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      End Date
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Earnings
                  </th>
                </tr>
              </thead>
              <tbody>
                {earningsData &&
                  earningsData.map((earnings, index) => (
                    <tr
                      className={classnames({
                        "bg-white": index % 2 == 0,
                        "bg-gray-100": index % 2 == 1,
                      })}
                      key={index}
                    >
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        {earnings.start_date}
                      </td>
                      {viewType === "weekly" && (
                        <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                          {earnings.end_date}
                        </td>
                      )}
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        ${earnings.amount}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
