import React, { useEffect, useState } from "react";
import organizationApi from "apis/organization";
import OrganizationForm from "./OrganizationForm";
import { PageLoader } from "neetoui";

const Edit = props => {
  const { organizationId, fetchOrganizationData, onCancel } = props;

  const [organization, setOrganization] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrganization();
  }, []);

  const fetchOrganization = async () => {
    try {
      setLoading(true);
      const response = await organizationApi.fetchOrganization(organizationId);
      setOrganization(response.data.organization);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <OrganizationForm
        organizationId={organizationId}
        initialFormValues={organization}
        fetchOrganizationData={fetchOrganizationData}
        onCancel={onCancel}
      />
    );
  }
};

export default Edit;
