import React from "react";
import NoDataImg from "assets/images/no_data.svg";

export default function EmptyData({ message }) {
  return (
    <div className="mt-20">
      <img src={NoDataImg} className="max-w-sm mx-auto mb-8" />
      <h3 className="text-xl font-semibold leading-4 text-center">
        {message ? message : "No Data Found"}
      </h3>
    </div>
  );
}
