import React from "react";
import { Pane } from "neetoui";
import Edit from "./Edit";

const OrganizationPane = props => {
  const { isOpen, onClose, organizationId, fetchOrganizationData } = props;

  return (
    <Pane title="Edit Organization Name" isOpen={isOpen} onClose={onClose}>
      <Edit
        organizationId={organizationId}
        fetchOrganizationData={fetchOrganizationData}
        onCancel={onClose}
      />
    </Pane>
  );
};

export default OrganizationPane;
