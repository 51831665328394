export const EARNING_VIEW_TYPES = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

export const INITIAL_FORM_VALUES = {
  start_date: "",
  end_date: "",
};
