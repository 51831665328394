import React from "react";
import { withRouter } from "react-router-dom";
import CommissionForm from "./CommissionForm";

const Create = props => {
  const {
    organizationId,
    fetchCommissionData,
    editPane,
    initialFormValues,
    onCancel,
  } = props;

  return (
    <CommissionForm
      organizationId={organizationId}
      initialFormValues={initialFormValues}
      editPane={editPane}
      fetchCommissionData={fetchCommissionData}
      onCancel={onCancel}
    />
  );
};

export default withRouter(Create);
