export const INITIAL_FORM_VALUES = {
  user_type: "",
  level: "",
  value: "",
};

export const USER_TYPES = [
  { value: "admin", label: "Admin" },
  { value: "caregiver", label: "Caregiver" },
];

export const LEVEL_TYPES = [
  { value: "level_1", label: "Level 1" },
  { value: "level_2", label: "Level 2" },
  { value: "level_3", label: "Level 3" },
];
