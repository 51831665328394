import React, { useState, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { PageLoader, Button } from "neetoui";
import classnames from "classnames";
import userApi from "apis/user";
import Profile from "./Account/Profile";
import PasswordEdit from "./Account/Passwords/Edit";
import Sidebar from "../Common/Sidebar";
import Organization from "./Organization";
import Dashboard from "./DashboardSummary";
import OrganizationSummary from "./DashboardSummary/OrganizationSummary";
import OrganizationUsers from "./Organization/OrganizationUsersTable";
import OrganizationSettings from "./OrganizationSettings";
import Users from "./Users";
import Referrals from "./Referrals";
import SelfReferrals from "./Referrals/SelfReferralsTable";
import ReferralForm from "./Referrals/ReferralForm";
import ReferralEarnings from "./Earnings";
import SelfReferralEarnings from "./Earnings/SelfEarningsTable";

const Home = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSidebarMob, setOpenSidebarMob] = useState(false);

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      setLoading(true);
      const response = await userApi.fetch();
      setUser(response.data.user);
      localStorage.setItem(
        "userOrganizationId",
        response.data.user.organization_id
      );
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderRedirect = user => {
    switch (user.role) {
      case "super_admin":
        return <Redirect from="/" to="/dashboard" />;
      case "admin":
        return <Redirect from="/" to="/referrals" />;
      case "sub_admin":
        return <Redirect from="/" to="/referrals" />;
      case "caregiver":
        return <Redirect from="/" to="/referrals" />;
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <Sidebar
          role={user?.role}
          openSidebarMob={openSidebarMob}
          setOpenSidebarMob={setOpenSidebarMob}
        />
        <div className="flex flex-col w-0 flex-1 overflow-scroll">
          <div
            className={classnames(
              "md:hidden px-2 py-4 bg-gray-100 w-full fixed top-0 ",
              {
                "z-40": !openSidebarMob,
                "z-20": openSidebarMob,
              }
            )}
          >
            <Button
              style="icon"
              className={classnames("ml-2", {
                "z-40": !openSidebarMob,
                "z-20": openSidebarMob,
              })}
              icon="ri-menu-2-line ri-lg"
              onClick={() => {
                setOpenSidebarMob(true);
              }}
            />
          </div>
          <Switch>
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/password/edit" component={PasswordEdit} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/organizations" component={Organization} />
            <Route
              exact
              path="/organization/summary"
              component={OrganizationSummary}
            />
            <Route
              exact
              path="/organizations/:organizationId"
              component={OrganizationUsers}
            />
            <Route exact path="/users" component={Users} />
            <Route exact path="/referrals" component={Referrals} />
            <Route exact path="/referrals/self" component={SelfReferrals} />
            <Route exact path="/referrals/new" component={ReferralForm} />
            <Route exact path="/settings" component={OrganizationSettings} />
            <Route
              exact
              path="/referral/earnings"
              component={ReferralEarnings}
            />
            <Route
              exact
              path="/referral/earnings/self"
              component={SelfReferralEarnings}
            />
            {renderRedirect(user)}
          </Switch>
        </div>
      </div>
    );
  }
};

export default Home;
