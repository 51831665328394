import React from "react";
import { Link, useLocation } from "react-router-dom";

const CaregiverNav = () => {
  const location = useLocation();
  return (
    <nav className="mt-8 flex-1 px-2 bg-white space-y-1">
      <Link
        to="/referrals"
        className={
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-100 hover:no-underline " +
          (location.pathname.includes("/referrals")
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900")
        }
      >
        <i
          className={
            "ri-user-add-line ri-lg mr-3 " +
            (location.pathname.includes("/referrals")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500")
          }
        ></i>
        Referrals
      </Link>
      <Link
        to="/referral/earnings"
        className={
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-100 hover:no-underline " +
          (location.pathname.includes("/referral/earnings")
            ? "bg-gray-100 text-gray-900"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900")
        }
      >
        <i
          className={
            "ri-money-dollar-circle-line ri-lg mr-3 " +
            (location.pathname.includes("/referral/earnings")
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500")
          }
        ></i>
        Earnings
      </Link>
    </nav>
  );
};

export default CaregiverNav;
