import axios from "axios";

const fetch = id => axios.get(`api/v1/organizations/${id}/commissions`);

const create = (id, payload) =>
  axios.post(`api/v1/organizations/${id}/commissions`, payload);

const update = (orgId, id, payload) =>
  axios.put(`api/v1/organizations/${orgId}/commissions/${id}`, payload);

const destroy = (orgId, id) =>
  axios.delete(`api/v1/organizations/${orgId}/commissions/${id}`);

const fetchCommission = (orgId, id) =>
  axios.get(`api/v1/organizations/${orgId}/commissions/${id}`);

const commissionApi = {
  fetch,
  create,
  update,
  destroy,
  fetchCommission,
};

export default commissionApi;
