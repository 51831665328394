import React, { useState, useEffect } from "react";
import { Button } from "neetoui";
import classnames from "classnames";
import userApi from "apis/user";

const index = ({
  earningsData,
  totalCount,
  pageIndex,
  pageSize,
  setPageIndex,
}) => {
  const [user, setUser] = useState("");
  const totalPages = Math.ceil(totalCount / pageSize);
  const pageIndexEnds = pageIndex * pageSize;
  const pageIndexStarts = pageIndexEnds - pageSize;

  const getNextIndex = function () {
    return pageIndex < totalPages ? pageIndex + 1 : pageIndex;
  };

  const getPrevIndex = function () {
    return pageIndex > 1 ? pageIndex - 1 : pageIndex;
  };

  const nextPage = function () {
    setPageIndex(getNextIndex());
  };

  const previousPage = function () {
    setPageIndex(getPrevIndex());
  };

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      const response = await userApi.fetch();
      setUser(response.data.user.role);
    } catch (error) {
      logger.error(error);
    }
  };

  const renderUserRole = userRole => {
    switch (userRole) {
      case "super_admin":
        return "Super Admin";
      case "admin":
        return "Admin";
      case "sub_admin":
        return "Sub Admin";
      case "caregiver":
        return "Caregiver";
      default:
        return "";
    }
  };
  return (
    <div className="flex flex-col mt-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className={classnames("shadow overflow-hidden", {
              "sm:rounded-t-lg": totalCount > pageSize,
              "sm:rounded-lg border-b border-gray-200": totalCount <= pageSize,
            })}
          >
            <table className="mgl-table--no-actions min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Referral Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone Number
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Earnings
                  </th>
                  {(user === "admin" || user === "sub_admin") && (
                    <>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Earned By
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Role
                      </th>
                    </>
                  )}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {earningsData &&
                  earningsData.map((earnings, index) => (
                    <tr
                      className={classnames({
                        "bg-white": index % 2 == 0,
                        "bg-gray-100": index % 2 == 1,
                      })}
                      key={earnings.id}
                    >
                      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-600">
                        {earnings.referral_name}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        {earnings.referral_phone_number
                          ? earnings.referral_phone_number
                          : "-"}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        ${earnings.amount}
                      </td>
                      {(user === "admin" || user === "sub_admin") && (
                        <>
                          <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                            {earnings.referred_by}
                          </td>
                          <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                            {renderUserRole(earnings.referred_by_role)}
                          </td>
                        </>
                      )}
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        {earnings.created_at}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {totalCount > pageSize && (
            <nav
              className="bg-white px-6 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg shadow"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  {totalCount < pageIndexEnds ? (
                    <span className="font-medium">
                      {pageIndexStarts + 1} - {totalCount} of {totalCount}
                    </span>
                  ) : (
                    <span className="font-medium">
                      {pageIndexStarts + 1} - {pageIndexEnds} of {totalCount}
                    </span>
                  )}{" "}
                  results
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <Button
                  style="secondary"
                  className={classnames({
                    "cursor-not-allowed": pageIndex === 1,
                  })}
                  label="Previous"
                  onClick={() => previousPage()}
                />
                <Button
                  style="secondary"
                  className={classnames("ml-3", {
                    "cursor-not-allowed": totalPages === pageIndex,
                  })}
                  label="Next"
                  onClick={() => nextPage()}
                />
              </div>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default index;
