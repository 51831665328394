import React, { useState, useEffect } from "react";
import { Button, PageLoader, DateRangeInput, Select } from "neetoui";
import classnames from "classnames";
import moment from "moment";
import referralApi from "apis/referrals";
import userApi from "apis/user";
import ReferralsTable from "./ReferralsTable";
import ViewTable from "./ViewTable";
import EmptyData from "../../Common/EmptyData";
import { FILTER_FORM_VALUES, EARNING_VIEW_TYPES } from "./constants";

export default function index() {
  const [organizationId, setOrganizationId] = useState(
    localStorage.getItem("userOrganizationId")
  );
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [referralData, setReferralData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(null);
  const [viewType, setViewType] = useState(null);
  const [filterValues, setFilterValues] = useState(FILTER_FORM_VALUES);
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    fetchReferralData();
  }, [pageIndex]);

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchReferralData = async () => {
    const payload = {
      params: {
        page: pageIndex,
        page_size: pageSize,
        scope: "all",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      if (organizationId) {
        const response = await referralApi.fetch(organizationId, payload);
        setReferralData(response.data.referrals);
        setTotalCount(response.data.total_count);
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const viewReferralData = async viewType => {
    const payload = {
      params: {
        view: viewType,
        scope: "all",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await referralApi.view(organizationId, payload);
      setReferralData(response.data.referrals);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserRole = async () => {
    try {
      const response = await userApi.fetch();
      setUserRole(response.data.user.role);
    } catch (error) {
      logger.error(error);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center">
              <h1 className="flex flex-row justify-center items-center text-2xl font-semibold text-gray-900 mb-4 lg:mb-0">
                <span
                  onClick={() => {
                    setViewType(null);
                    fetchReferralData();
                  }}
                  className={classnames("cursor-pointer ", {
                    "text-gray-600 hover:text-gray-700": viewType,
                  })}
                >
                  Referrals
                </span>
                {viewType && (
                  <>
                    <i className="ri-arrow-right-s-line mr-2"></i>
                    <span className="capitalize">{viewType} View</span>
                  </>
                )}
              </h1>
              <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-end lg:items-end">
                <DateRangeInput
                  className="mb-4 lg:mb-0"
                  value={dateRange}
                  onChange={selected => {
                    setDateRange(selected);
                    setFilterValues({
                      start_date: selected[0],
                      end_date: selected[1],
                    });
                  }}
                  format="DD-MM-YYYY"
                  minDate={moment("2010-01-01").toDate()}
                  maxDate={moment().toDate()}
                />
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 ml-0 lg:ml-3 w-full lg:w-auto">
                  <Select
                    name="view"
                    placeholder="View"
                    value={EARNING_VIEW_TYPES.find(
                      obj => obj.value === viewType
                    )}
                    onChange={selected => {
                      setViewType(selected.value);
                      viewReferralData(selected.value);
                    }}
                    options={EARNING_VIEW_TYPES}
                  />
                  <Button
                    style="primary"
                    icon="ri-filter-line ri-lg"
                    label="Filter"
                    onClick={() => {
                      if (viewType === null) {
                        fetchReferralData();
                      } else {
                        viewReferralData(viewType);
                      }
                    }}
                  />
                  <Button
                    style="primary"
                    label="New Referral"
                    icon="ri-add-line ri-lg"
                    to={"/referrals/new"}
                  />
                </div>
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-6 lg:px-8">
              <div className="py-4">
                {referralData.length > 0 ? (
                  viewType ? (
                    <ViewTable
                      viewType={viewType}
                      referralData={referralData}
                    />
                  ) : (
                    <ReferralsTable
                      user={userRole}
                      organizationId={organizationId}
                      referralData={referralData}
                      fetchReferralData={fetchReferralData}
                      totalCount={totalCount}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      setPageIndex={setPageIndex}
                    />
                  )
                ) : (
                  <EmptyData message="No Referral Data Found" />
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
