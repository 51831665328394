import React, { useState } from "react";
import { Button, Input } from "neetoui";
import { Toastr } from "neetoui";
import authenticationApi from "apis/authentication";
import Logo from "assets/images/logo.svg";
import BannerLogin from "assets/images/login_banner.jpg";

const ResetPassword = () => {
  const [userEmail, setUserEmail] = useState("");

  const sendResetPasswordEmail = async () => {
    const payload = {
      user: {
        email: userEmail,
      },
    };
    try {
      const response = await authenticationApi.resetPasswordEmail(payload);
      if (response.data.notice) {
        Toastr.success(response.data.notice);
      } else if (response.status == 201) {
        Toastr.success("Reset Password mail has been successfully sent.");
      }
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="text-center sm:text-left">
            <img
              className="h-16 w-auto m-auto sm:m-0"
              src={Logo}
              alt="Medigap Referral Network"
            />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Reset Password
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form className="space-y-6">
                <Input
                  required
                  type="email"
                  value={userEmail}
                  label="Email Address"
                  id="user_email"
                  onChange={e => setUserEmail(e.target.value)}
                />
                <div className="flex items-center justify-center sm:justify-end">
                  <Button
                    style="link"
                    label="Already have an account?"
                    to="/login"
                  />
                </div>

                <div>
                  <button
                    onClick={e => {
                      e.preventDefault();
                      sendResetPasswordEmail();
                    }}
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Send Reset Password Email
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={BannerLogin}
          alt="Medicare Referral Network"
        />
      </div>
    </div>
  );
};

export default ResetPassword;
