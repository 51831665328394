import React, { useEffect, useState } from "react";
import { PageLoader, Button, Tooltip, DateRangeInput, Select } from "neetoui";
import moment from "moment";
import classnames from "classnames";
import referralApi from "apis/referrals";
import EmptyData from "../../Common/EmptyData";
import ReferralModal from "./ReferralDataModal";
import ViewTable from "./ViewTable";
import { FILTER_FORM_VALUES, EARNING_VIEW_TYPES } from "./constants";

const index = () => {
  const [showModal, setShowModal] = useState(false);
  const [organizationId, setOrganizationId] = useState(
    localStorage.getItem("userOrganizationId")
  );
  const [loading, setLoading] = useState(false);
  const [referralData, setReferralData] = useState([]);
  const [referralId, setReferralId] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(null);
  const [viewType, setViewType] = useState(null);
  const [filterValues, setFilterValues] = useState(FILTER_FORM_VALUES);
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    fetchReferralData();
  }, [pageIndex]);

  const fetchReferralData = async () => {
    const payload = {
      params: {
        page: pageIndex,
        page_size: pageSize,
        scope: "self",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await referralApi.fetch(organizationId, payload);
      setReferralData(response.data.referrals);
      setTotalCount(response.data.total_count);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const viewReferralData = async viewType => {
    const payload = {
      params: {
        view: viewType,
        scope: "all",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await referralApi.view(organizationId, payload);
      setReferralData(response.data.referrals);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);
  const pageIndexEnds = pageIndex * pageSize;
  const pageIndexStarts = pageIndexEnds - pageSize;

  const getNextIndex = function () {
    return pageIndex < totalPages ? pageIndex + 1 : pageIndex;
  };

  const getPrevIndex = function () {
    return pageIndex > 1 ? pageIndex - 1 : pageIndex;
  };

  const nextPage = function () {
    setPageIndex(getNextIndex());
  };

  const previousPage = function () {
    setPageIndex(getPrevIndex());
  };

  const handleDelete = async referralId => {
    try {
      await referralApi.destroy(organizationId, referralId);
      fetchReferralData();
    } catch (error) {
      logger.error(error);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center">
              <h1 className="flex flex-row justify-center items-center text-xl font-semibold text-gray-900 mb-4 lg:mb-0">
                <span
                  onClick={() => {
                    setViewType(null);
                    fetchReferralData();
                  }}
                  className={classnames("cursor-pointer ", {
                    "text-gray-600 hover:text-gray-700": viewType,
                  })}
                >
                  My Referrals
                </span>
                {viewType && (
                  <>
                    <i className="ri-arrow-right-s-line mr-2"></i>
                    <span className="capitalize">{viewType} View</span>
                  </>
                )}
              </h1>
              <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-end lg:items-end">
                <DateRangeInput
                  className="mb-4 lg:mb-0"
                  value={dateRange}
                  onChange={selected => {
                    setDateRange(selected);
                    setFilterValues({
                      start_date: selected[0],
                      end_date: selected[1],
                    });
                  }}
                  format="DD-MM-YYYY"
                  minDate={moment("2010-01-01").toDate()}
                  maxDate={moment().toDate()}
                />
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 ml-0 lg:ml-3 w-full lg:w-auto">
                  <Select
                    name="view"
                    placeholder="View"
                    value={EARNING_VIEW_TYPES.find(
                      obj => obj.value === viewType
                    )}
                    onChange={selected => {
                      setViewType(selected.value);
                      viewReferralData(selected.value);
                    }}
                    options={EARNING_VIEW_TYPES}
                  />
                  <Button
                    style="primary"
                    icon="ri-filter-line ri-lg"
                    label="Filter"
                    onClick={() => {
                      if (viewType === null) {
                        fetchReferralData();
                      } else {
                        viewReferralData(viewType);
                      }
                    }}
                  />
                  <Button
                    style="primary"
                    label="New Referral"
                    icon="ri-add-line ri-lg"
                    to={"/referrals/new"}
                  />
                </div>
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="py-4">
                {referralData.length > 0 ? (
                  viewType ? (
                    <ViewTable
                      viewType={viewType}
                      referralData={referralData}
                    />
                  ) : (
                    <div className="flex flex-col mt-6">
                      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                          <div
                            className={classnames("shadow overflow-hidden", {
                              "rounded-t-lg": totalCount > pageSize,
                              "rounded-lg border-b border-gray-200":
                                totalCount <= pageSize,
                            })}
                          >
                            <table className="mgl-table min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Phone Number
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Status
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {referralData &&
                                  referralData.map((referral, index) => (
                                    <tr
                                      className={classnames({
                                        "bg-white": index % 2 == 0,
                                        "bg-gray-100": index % 2 == 1,
                                      })}
                                      key={referral.id}
                                      onClick={() => {
                                        setReferralId(referral.id);
                                        setShowModal(true);
                                      }}
                                    >
                                      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">
                                        {referral.full_name}
                                      </td>
                                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                        {referral.phone_number}
                                      </td>
                                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                        {referral.email}
                                      </td>
                                      <td className="px-6 py-2 whitespace-nowrap">
                                        <span
                                          className={classnames(
                                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize",
                                            {
                                              "bg-green-100 text-green-600":
                                                referral.status === "approved",
                                              "bg-red-100 text-red-600":
                                                referral.status === "rejected",
                                              "bg-yellow-100 text-yellow-600":
                                                referral.status === "pending",
                                            }
                                          )}
                                        >
                                          {referral.status}
                                        </span>
                                      </td>
                                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                        {referral.created_at}
                                      </td>
                                      <td className="flex flex-row justify-end items-center px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                                        <Tooltip content="View Referral">
                                          <Button
                                            className="mr-3"
                                            style="icon"
                                            icon="ri-user-line"
                                            onClick={e => {
                                              e.stopPropagation();
                                              setReferralId(referral.id);
                                              setShowModal(true);
                                            }}
                                          />
                                        </Tooltip>
                                        <Tooltip content="Delete">
                                          <Button
                                            style="icon"
                                            icon="ri-delete-bin-line"
                                            onClick={e => {
                                              e.stopPropagation();
                                              handleDelete(referral.id);
                                            }}
                                          />
                                        </Tooltip>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          {totalCount > pageSize && (
                            <nav
                              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg shadow"
                              aria-label="Pagination"
                            >
                              <div className="hidden sm:block">
                                <p className="text-sm text-gray-700">
                                  Showing{" "}
                                  {totalCount < pageIndexEnds ? (
                                    <span className="font-medium">
                                      {pageIndexStarts + 1} - {totalCount} of{" "}
                                      {totalCount}
                                    </span>
                                  ) : (
                                    <span className="font-medium">
                                      {pageIndexStarts + 1} - {pageIndexEnds} of{" "}
                                      {totalCount}
                                    </span>
                                  )}{" "}
                                  results
                                </p>
                              </div>
                              <div className="flex-1 flex justify-between sm:justify-end">
                                <Button
                                  style="secondary"
                                  className={classnames({
                                    "cursor-not-allowed": pageIndex === 1,
                                  })}
                                  label="Previous"
                                  onClick={() => previousPage()}
                                />
                                <Button
                                  style="secondary"
                                  className={classnames("ml-3", {
                                    "cursor-not-allowed":
                                      totalPages === pageIndex,
                                  })}
                                  label="Next"
                                  onClick={() => nextPage()}
                                />
                              </div>
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <EmptyData message="No Earnings Data Found" />
                )}
              </div>
            </div>
          </div>
        </main>
        {showModal && (
          <ReferralModal
            referralId={referralId}
            showModal={showModal}
            onModalCancel={() => setShowModal(false)}
          />
        )}
      </>
    );
  }
};

export default index;
