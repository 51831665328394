import React, { useState } from "react";
import { Button, Switch, Input } from "neetoui";
import organizationApi from "apis/organization";

const OrganizationForm = props => {
  const {
    organizationId,
    initialFormValues,
    fetchOrganizations,
    onCancel,
  } = props;
  const [formValues, setFormValues] = useState(initialFormValues);

  const handleSubmit = async () => {
    try {
      const payload = {
        organization: formValues,
      };
      await organizationApi.create(payload);
      onCancel();
      fetchOrganizations();
    } catch (err) {
      logger.error(err);
    }
  };

  const handleUpdate = async () => {
    try {
      const payload = {
        organization: {
          name: formValues.name,
          enabled: formValues.enabled,
        },
      };
      await organizationApi.update(organizationId, payload);
      onCancel();
      fetchOrganizations();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <>
      <div className="px-6 pb-20">
        <Input
          label="Organization Name"
          required
          value={formValues.name}
          onChange={e =>
            setFormValues({
              ...formValues,
              name: e.target.value,
            })
          }
          name="name"
          type="text"
          className="mb-6"
          autoFocus
        />
        <div className="w-full">
          <div className="flex flex-row justify-start items-center w-full mb-6">
            <label className="mr-4">Organization Status</label>
            <Switch
              checked={formValues.enabled}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  enabled: !formValues.enabled,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="nui-pane__footer nui-pane__footer--absolute">
        <Button
          onClick={onCancel}
          label="Cancel"
          size="large"
          style="secondary"
        />
        <Button
          onClick={organizationId ? handleUpdate : handleSubmit}
          className="ml-2"
          type="submit"
          label={organizationId ? "Update" : "Submit"}
          size="large"
          style="primary"
        />
      </div>
    </>
  );
};

export default OrganizationForm;
