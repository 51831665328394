export const INITIAL_FORM_VALUES = {
  first_name: "",
  last_name: "",
  phone_number: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  date_of_birth: "",
};
