import axios from "axios";

const fetch = (id, payload) =>
  axios.get(`api/v1/organizations/${id}/users`, payload);

const create = (id, payload) =>
  axios.post(`api/v1/organizations/${id}/users`, payload);

const update = (orgId, id, payload) =>
  axios.put(`api/v1/organizations/${orgId}/users/${id}`, payload);

const destroy = (orgId, id) =>
  axios.delete(`api/v1/organizations/${orgId}/users/${id}`);

const fetchUser = (orgId, id) =>
  axios.get(`api/v1/organizations/${orgId}/users/${id}`);

const usersApi = {
  fetch,
  create,
  update,
  destroy,
  fetchUser,
};

export default usersApi;
