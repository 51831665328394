import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { PageLoader, Button, DateRangeInput } from "neetoui";
import moment from "moment";
import dashboardApi from "apis/dashboard";

export default function index() {
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({
    start_date: "",
    end_date: "",
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [referralData, setReferralData] = useState([]);
  const [referralEarningsData, setReferralEarningsData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const payload = {
      params: {
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const referral = await dashboardApi.fetchReferral(payload);
      const referral_earnings = await dashboardApi.fetchReferralEarnings(
        payload
      );
      const organization = await dashboardApi.fetchOrganization(payload);

      setReferralData(referral.data);
      setReferralEarningsData(referral_earnings.data);
      setOrganizationData(organization.data);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendReport = async () => {
    const payload = {
      params: {
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      await dashboardApi.fetchOrganizationReport(payload);
    } catch (error) {
      logger.error(error);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center">
              <h1 className="text-2xl font-semibold text-gray-900 mb-4 sm:mb-0">
                Dashboard
              </h1>
              <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-end lg:items-center">
                <DateRangeInput
                  className="mb-4 lg:mb-0"
                  value={dateRange}
                  onChange={selected => {
                    setDateRange(selected);
                    setFilterValues({
                      start_date: selected[0],
                      end_date: selected[1],
                    });
                  }}
                  format="DD-MM-YYYY"
                  minDate={moment("2010-01-01").toDate()}
                  maxDate={moment().toDate()}
                />
                <Button
                  style="primary"
                  className="ml-0 lg:ml-3"
                  icon="ri-filter-line ri-lg"
                  label="Filter"
                  onClick={() => {
                    loadData();
                  }}
                />
                <Button
                  style="primary"
                  className="mt-2 ml-0 lg:ml-3 lg:mt-0"
                  icon="ri-mail-line ri-lg"
                  label="Send Report"
                  onClick={() => {
                    sendReport();
                  }}
                />
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-8 mt-6">
              <div className="py-4">
                <div className="mb-10">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Organizations Summary
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Active Organizations
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-green-600">
                          {organizationData.enabled}
                        </div>
                      </dd>
                    </div>

                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Inactive Organizations
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-red-600">
                          {organizationData.disabled}
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Total Organizations
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-indigo-600">
                          {organizationData.total}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="mb-10">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Referrals Summary
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-4 md:divide-y-0 md:divide-x">
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Pending Referrals
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-yellow-400">
                          {referralData.pending}
                        </div>
                      </dd>
                    </div>

                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Approved Referrals
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-green-500">
                          {referralData.approved}
                        </div>
                      </dd>
                    </div>

                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Rejected Referrals
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-red-500">
                          {referralData.rejected}
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Total Referrals
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-indigo-600">
                          {referralData.total}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="mb-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Referral Earnings Summary
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-4 md:divide-y-0 md:divide-x">
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Admin Earnings
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-indigo-500">
                          ${referralEarningsData.admin_earnings}
                        </div>
                      </dd>
                    </div>

                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Caregiver Earnings
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-indigo-500">
                          ${referralEarningsData.caregiver_earnings}
                        </div>
                      </dd>
                    </div>

                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Referrals Count
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-indigo-500">
                          {referralEarningsData.referrals_count}
                        </div>
                      </dd>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Total Earnings
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-bold text-indigo-600">
                          ${referralEarningsData.total_earnings}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
