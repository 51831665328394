import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { Button, Tooltip } from "neetoui";
import organizationApi from "apis/organization";

const index = ({
  organizations,
  setShowPane,
  setOrganizationId,
  fetchOrganizations,
  totalCount,
  pageIndex,
  pageSize,
  setPageIndex,
}) => {
  const history = useHistory();
  const totalPages = Math.ceil(totalCount / pageSize);
  const pageIndexEnds = pageIndex * pageSize;
  const pageIndexStarts = pageIndexEnds - pageSize;

  const getNextIndex = function () {
    return pageIndex < totalPages ? pageIndex + 1 : pageIndex;
  };

  const getPrevIndex = function () {
    return pageIndex > 1 ? pageIndex - 1 : pageIndex;
  };

  const nextPage = function () {
    setPageIndex(getNextIndex());
  };

  const previousPage = function () {
    setPageIndex(getPrevIndex());
  };

  const handleDelete = async organizationId => {
    try {
      await organizationApi.destroy(organizationId);
      fetchOrganizations();
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <div className="flex flex-col mt-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className={classnames("shadow overflow-hidden", {
              "sm:rounded-t-lg": totalCount > pageSize,
              "sm:rounded-lg border-b border-gray-200": totalCount <= pageSize,
            })}
          >
            <table className="mgl-table min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {organizations &&
                  organizations.map((organization, index) => (
                    <tr
                      className={classnames({
                        "bg-white": index % 2 == 0,
                        "bg-gray-100": index % 2 == 1,
                      })}
                      key={organization.id}
                      onClick={() => {
                        history.push(`/organizations/${organization.id}`);
                      }}
                    >
                      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">
                        {organization.name}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        {organization.created_at}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <span
                          className={classnames(
                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                            {
                              "bg-green-100 text-green-600":
                                organization.enabled === true,
                              "bg-red-100 text-red-600":
                                organization.enabled === false,
                            }
                          )}
                        >
                          {organization.enabled ? "Enabled " : "Disabled"}
                        </span>
                      </td>
                      <td className="flex flex-row justify-end items-center px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                        <Tooltip content="View Users">
                          <Button
                            className="mr-3"
                            style="icon"
                            icon="ri-group-line"
                            onClick={e => {
                              e.stopPropagation();
                              history.push(`/organizations/${organization.id}`);
                            }}
                          />
                        </Tooltip>
                        <Tooltip content="Edit">
                          <Button
                            className="mr-3"
                            style="icon"
                            icon="ri-pencil-line"
                            onClick={e => {
                              e.stopPropagation();
                              setOrganizationId(organization.id);
                              setShowPane(true);
                            }}
                          />
                        </Tooltip>
                        <Tooltip content="Delete">
                          <Button
                            style="icon"
                            icon="ri-delete-bin-line"
                            onClick={e => {
                              e.stopPropagation();
                              handleDelete(organization.id);
                            }}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {totalCount > pageSize && (
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg shadow"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  {totalCount < pageIndexEnds ? (
                    <span className="font-medium">
                      {pageIndexStarts + 1} - {totalCount} of {totalCount}
                    </span>
                  ) : (
                    <span className="font-medium">
                      {pageIndexStarts + 1} - {pageIndexEnds} of {totalCount}
                    </span>
                  )}{" "}
                  results
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                <Button
                  style="secondary"
                  className={classnames({
                    "cursor-not-allowed": pageIndex === 1,
                  })}
                  label="Previous"
                  onClick={() => previousPage()}
                />
                <Button
                  style="secondary"
                  className={classnames("ml-3", {
                    "cursor-not-allowed": totalPages === pageIndex,
                  })}
                  label="Next"
                  onClick={() => nextPage()}
                />
              </div>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default index;
