import axios from "axios";

const login = payload => axios.post("api/v1/login", payload);

const logout = () => axios.delete("api/v1/logout");

const signup = payload => axios.post("api/v1/users", payload);

const resetPasswordEmail = payload =>
  axios.post("api/v1/password/reset", payload);
const resetPassword = payload => axios.put("api/v1/password/reset", payload);

const authenticationApi = {
  login,
  logout,
  signup,
  resetPasswordEmail,
  resetPassword,
};

export default authenticationApi;
