import React, { useState } from "react";
import { Pane } from "neetoui";
import Create from "./Create";
import Edit from "./Edit";
import { INITIAL_FORM_VALUES } from "../constants";

const OrganizationPane = props => {
  const { isOpen, onClose, organizationId, fetchOrganizations } = props;
  const [initialFormValues, setInitialFormValues] = useState(
    INITIAL_FORM_VALUES
  );

  return (
    <Pane
      title={organizationId ? "Edit Organization" : "Create Organization"}
      isOpen={isOpen}
      onClose={onClose}
    >
      {organizationId ? (
        <Edit
          organizationId={organizationId}
          fetchOrganizations={fetchOrganizations}
          onCancel={onClose}
        />
      ) : (
        <Create
          initialFormValues={initialFormValues}
          fetchOrganizations={fetchOrganizations}
          onCancel={onClose}
        />
      )}
    </Pane>
  );
};

export default OrganizationPane;
