import React, { useState } from "react";
import classnames from "classnames";
import { Button, Tooltip, Alert } from "neetoui";
import commissionApi from "apis/commissions";

const index = ({
  organizationId,
  commissionData,
  setShowPane,
  setCommmissionId,
  fetchCommissionData,
}) => {
  const handleDelete = async commissionId => {
    try {
      await commissionApi.destroy(organizationId, commissionId);
      fetchCommissionData();
    } catch (error) {
      logger.error(error);
    }
  };

  const renderCommissionName = userRole => {
    if (userRole == "super_admin") {
      return "Super Admin";
    } else if (userRole == "admin") {
      return "Admin";
    } else if (userRole == "sub_admin") {
      return "Sub Admin";
    } else if (userRole == "caregiver") {
      return "Caregiver";
    }
  };

  const renderCommissionLevel = userLevel => {
    if (userLevel == "level_1") {
      return "Level 1";
    } else if (userLevel == "level_2") {
      return "Level 2";
    } else if (userLevel == "level_3") {
      return "Level 3";
    }
  };

  return (
    <div className="flex flex-col mt-3">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="mgl-table min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User Level
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Commission Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {commissionData &&
                  commissionData.map((commission, index) => (
                    <tr
                      className={classnames({
                        "bg-white": index % 2 == 0,
                        "bg-gray-100": index % 2 == 1,
                      })}
                      key={commission.id}
                    >
                      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">
                        {renderCommissionName(commission.user_type)}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        {renderCommissionLevel(commission.level)}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        ${commission.value}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                        {commission.created_at}
                      </td>
                      <td className="flex flex-row justify-end items-center px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
                        <Tooltip content="Edit">
                          <Button
                            className="mr-3"
                            style="icon"
                            icon="ri-pencil-line"
                            onClick={() => {
                              setCommmissionId(commission.id);
                              setShowPane(true);
                            }}
                          />
                        </Tooltip>
                        <Tooltip content="Delete">
                          <Button
                            style="icon"
                            icon="ri-delete-bin-line"
                            onClick={() => {
                              handleDelete(commission.id);
                            }}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
