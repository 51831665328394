export const INITIAL_FORM_VALUES = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone_number: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  date_of_birth: new Date(),
  role: "",
  is_active: true,
  commission_id: "",
};

export const USER_TYPES = [
  { value: "sub_admin", label: "Sub Admin" },
  { value: "caregiver", label: "Caregiver" },
];
