import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Input, Toastr, DateInput } from "neetoui";
import { useUserState } from "contexts/user";
import userApi from "apis/user";
import { INITIAL_FORM_VALUES } from "./constants";

const Profile = () => {
  const { user } = useUserState();

  const [email, setEmail] = useState("");
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await userApi.fetch();
      setUserId(response.data.user.id);
      if (response.data.user) {
        const details = await userApi.fetchUser(response.data.user.id);
        setUserData(details.data.user);
      }
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    if (userData) {
      setFormValues(userData);
      setEmail(user.email);
    }
  }, [userData]);

  const handleUpdate = async () => {
    try {
      const payload = {
        user: {
          ...formValues,
        },
      };
      const response = await userApi.update(userId, payload);
      if (response.success) {
        Toastr.success("Successfully updated user details.");
      }
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <div className="flex flex-grow w-full wrapper">
      <div className="w-full px-4">
        <div className="flex flex-col items-center justify-center flex-grow w-full h-full pt-16 sm:py-8 mx-auto lg:w-5/12">
          <h2 className="mb-5 text-2xl font-medium text-center text-gray-800">
            Basic Details
          </h2>
          <div
            className="w-full px-10 py-8 bg-white border rounded-lg shadow-sm"
            id="edit_user"
          >
            <Input
              label="Email"
              value={email}
              disabled
              name="email"
              type="email"
              className="mb-6"
            />
            <Input
              label="First Name"
              name="first_name"
              type="text"
              className="mb-6"
              value={formValues.first_name}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  first_name: e.target.value,
                })
              }
            />
            <Input
              label="Last Name"
              name="last_name"
              type="text"
              className="mb-6"
              value={formValues.last_name}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  last_name: e.target.value,
                })
              }
            />
            <Input
              label="Phone Number"
              name="phone_number"
              type="tel"
              className="mb-6"
              value={formValues.phone_number}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  phone_number: e.target.value,
                })
              }
            />
            <Input
              label="Address"
              name="address"
              type="text"
              className="mb-6"
              value={formValues.address}
              onChange={e =>
                setFormValues({
                  ...formValues,
                  address: e.target.value,
                })
              }
            />
            <div className="mb-6 grid grid-cols-2 gap-4">
              <Input
                label="City"
                name="city"
                type="text"
                value={formValues.city}
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    city: e.target.value,
                  })
                }
              />
              <Input
                label="State"
                name="state"
                type="text"
                value={formValues.state}
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    state: e.target.value,
                  })
                }
              />
            </div>
            <div className="mb-6 grid grid-cols-2 gap-4">
              <Input
                label="Zip Code"
                name="zip_code"
                type="text"
                value={formValues.zip_code}
                onChange={e =>
                  setFormValues({
                    ...formValues,
                    zip_code: e.target.value,
                  })
                }
              />
              <DateInput
                name="date_of_birth"
                type="date"
                value={new Date(formValues.date_of_birth)}
                label="Date of Birth"
                format={"DD/MM/YYYY"}
                placeholder="DD/MM/YYYY"
                minDate={moment("1940-01-01").toDate()}
                maxDate={moment().toDate()}
                onChange={date =>
                  setFormValues({
                    ...formValues,
                    date_of_birth: date,
                  })
                }
              />
            </div>
            <div className="flex justify-center items-center w-full pt-2">
              <Button
                style="primary"
                className="w-full flex justify-center items-center"
                label="Update"
                onClick={() => handleUpdate()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
