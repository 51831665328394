import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { Form, Formik } from "formik";
import { Input } from "neetoui/formik";
import { Button, Checkbox, DateInput, Label } from "neetoui";
import { INITIAL_FORM_VALUES } from "./constants";
import referralApi from "apis/referrals";

const ReferralForm = () => {
  const organizationId = localStorage.getItem("userOrganizationId");
  const history = useHistory();

  const handleSubmit = async values => {
    const payload = {
      referral: {
        ...values,
      },
    };
    try {
      await referralApi.create(organizationId, payload);
      onCancel();
    } catch (error) {
      logger.error(error);
    }
  };

  const onCancel = () => {
    history.push("/referrals");
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone_number: Yup.number().required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip_code: Yup.number().required("Zip code is required"),
    date_of_birth: Yup.date().required("Date of birth is required"),
  });

  return (
    <div className="flex flex-row items-start justify-center py-6 pt-16 lg:pt-6">
      <div className="w-full md:w-140 px-4 lg:py-8">
        <h1 className="text-2xl font-semibold text-gray-900 text-center">
          Referral Form
        </h1>
        <div className="py-8">
          <Formik
            initialValues={INITIAL_FORM_VALUES}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ values, setValues }) => {
              return (
                <Form>
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <Input
                      name="first_name"
                      type="text"
                      required={true}
                      label="First Name"
                      autoFocus={true}
                    />
                    <Input
                      name="last_name"
                      type="text"
                      required={true}
                      label="Last Name"
                    />
                  </div>
                  <Input
                    name="email"
                    type="email"
                    required={true}
                    label="Email"
                    className="mb-6"
                  />
                  <Input
                    name="phone_number"
                    type="tel"
                    required={true}
                    label="Phone Number"
                    className="mb-6"
                  />
                  <Input
                    name="address"
                    type="text"
                    required={true}
                    label="Address"
                    className="mb-6"
                  />
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <Input
                      name="city"
                      type="text"
                      required={true}
                      label="City"
                    />
                    <Input
                      name="state"
                      type="text"
                      required={true}
                      label="State"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <Input
                      name="zip_code"
                      type="number"
                      required={true}
                      label="Zip Code"
                    />
                    <DateInput
                      name="date_of_birth"
                      type="date"
                      required={true}
                      value={values.date_of_birth}
                      label="Date of Birth"
                      format={"DD/MM/YYYY"}
                      placeholder="DD/MM/YYYY"
                      minDate={moment("1940-01-01").toDate()}
                      maxDate={moment().toDate()}
                      onChange={date =>
                        setValues({
                          ...values,
                          date_of_birth: date,
                        })
                      }
                    />
                  </div>
                  <div className="border-t border-gray-200 pt-4 pb-6">
                    <DateInput
                      value={values.appointment}
                      name="appointment"
                      type="date"
                      label="Appointment Date & Time"
                      minDate={moment().toDate()}
                      placeholder="Select Appointment Date & Time"
                      format={"DD/MM/YYYY - HH:mm A"}
                      onChange={date =>
                        setValues({
                          ...values,
                          appointment: date,
                        })
                      }
                      timePickerProps={{
                        useAmPm: true,
                      }}
                      closeOnSelection={false}
                    />
                  </div>
                  <div className="border-t border-gray-200 py-4 mb-4 flex flex-row justify-between items-start">
                    <Checkbox
                      name="tcpa_consent"
                      className="mr-2"
                      checked={values.tcpa_consent}
                      onChange={e => {
                        setValues({
                          ...values,
                          tcpa_consent: !values.tcpa_consent,
                        });
                      }}
                    />
                    <Label className="text-justify">
                      By clicking &apos;Submit&apos; I confirm that the above
                      mentioned client has provided me consent and authorization
                      to have a representative from Medigap Life makecontact
                      through the use of a predictive dialer, SMS, or email,
                      even if on the DNC list to discuss Medicare any and all
                      life or health insurance products including Medicare
                      Advantage.
                    </Label>
                  </div>
                  <div className="pt-4 flex flex-row justify-center items-center">
                    <Button
                      onClick={onCancel}
                      label="Cancel"
                      size="large"
                      style="secondary"
                    />
                    <Button
                      disabled={!values.tcpa_consent}
                      className="ml-2"
                      type="submit"
                      label="Submit"
                      size="large"
                      style="primary"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ReferralForm;
