import React from "react";
import { toast, Slide } from "react-toastify";
import { Toastr } from "neetoui";

export const showToastr = (type, message, buttonProps) => {
  toast[type](
    <Toastr type={type} message={message} buttonProps={buttonProps} />,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      transition: Slide,
    }
  );
};

export const showToastrError = (errObj, buttonProps) => {
  let message;
  if (errObj.error) {
    message = errObj.error;
  } else if (errObj.errors) {
    message = errObj.errors.base
      ? R.join("\n", errObj.errors.base)
      : R.join("\n", errObj.errors);
  } else {
    message = "Something went wrong.";
  }
  showToastr("error", message, buttonProps);
};
