import React from "react";

const Pagination = (pageCount, pageData = [], setPageCount) => {
  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg shadow"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing
          <span className="font-medium px-2">{pageCount}</span>
          to
          {/* <span className="font-medium px-2">
            {pageCount * 10 < pageData.length
              ? pageCount * 10
              : pageData.length}
          </span>
          of
          <span className="font-medium px-2">{pageData.length}</span>
          results */}
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <a
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => setPageCount(pageCount + 1)}
        >
          Previous
        </a>
        <a
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => {
            pageCount > 1 ? setPageCount(pageCount - 1) : setPageCount(1);
          }}
        >
          Next
        </a>
      </div>
    </nav>
  );
};

export default Pagination;
