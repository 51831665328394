import React, { useState } from "react";
import { Button, Input } from "neetoui";
import organizationApi from "apis/organization";

const OrganizationForm = props => {
  const {
    organizationId,
    initialFormValues,
    fetchOrganizationData,
    onCancel,
  } = props;
  const [formValues, setFormValues] = useState(initialFormValues);

  const handleUpdate = async () => {
    try {
      const payload = {
        organization: {
          name: formValues.name,
        },
      };
      await organizationApi.update(organizationId, payload);
      onCancel();
      fetchOrganizationData();
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <>
      <div className="px-6 pb-20">
        <Input
          label="Organization Name"
          required
          value={formValues.name}
          onChange={e =>
            setFormValues({
              ...formValues,
              name: e.target.value,
            })
          }
          name="name"
          type="text"
          className="mb-6"
          autoFocus
        />
      </div>
      <div className="nui-pane__footer nui-pane__footer--absolute">
        <Button
          onClick={onCancel}
          label="Cancel"
          size="large"
          style="secondary"
        />
        <Button
          onClick={handleUpdate}
          className="ml-2"
          type="submit"
          label="Update"
          size="large"
          style="primary"
        />
      </div>
    </>
  );
};

export default OrganizationForm;
