import React from "react";
import { withRouter } from "react-router-dom";
import UserForm from "./UserForm";

const Create = props => {
  const {
    organizationId,
    commissionData,
    initialFormValues,
    onCancel,
    fetchUsersData,
  } = props;

  return (
    <UserForm
      organizationId={organizationId}
      initialFormValues={initialFormValues}
      onCancel={onCancel}
      commissionData={commissionData}
      fetchUsersData={fetchUsersData}
    />
  );
};

export default withRouter(Create);
