import React, { useState } from "react";
import classnames from "classnames";
import { resetAuthTokens } from "apis/axios";
import authenticationApi from "apis/authentication";
import { useAuthDispatch } from "contexts/auth";

import Logo from "assets/images/logo.svg";
import SuperAdminNav from "./SuperAdminNav";
import AdminNav from "./AdminNav";
import SubAdminNav from "./SubAdminNav";
import CaregiverNav from "./CaregiverNav";
import AccountDropdown from "./AccountDropdown";
import Transition from "../../Common/Transition";

const Sidebar = ({ role, openSidebarMob, setOpenSidebarMob }) => {
  const renderSidebarNav = userRole => {
    switch (userRole) {
      case "super_admin":
        return <SuperAdminNav />;
      case "admin":
        return <AdminNav />;
      case "sub_admin":
        return <SubAdminNav />;
      case "caregiver":
        return <CaregiverNav />;
      default:
        return <CaregiverNav />;
    }
  };

  const authDispatch = useAuthDispatch();

  const handleLogout = async () => {
    try {
      await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
      resetAuthTokens();
      window.location.href = "/login";
    } catch (error) {
      Toastr.error(error);
    }
  };

  return (
    <>
      <div
        className={classnames("md:hidden ", {
          "z-40": openSidebarMob,
          "z-0": !openSidebarMob,
        })}
      >
        <div className="fixed inset-0 flex">
          <Transition
            show={openSidebarMob}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            appear
          >
            <div className="fixed inset-0" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
          </Transition>
          <Transition
            show={openSidebarMob}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            appear
          >
            <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  onClick={() => setOpenSidebarMob(false)}
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-shrink-0 px-4 flex items-center">
                <img src={Logo} className="h-12" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                {renderSidebarNav(role)}
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <div className="flex-shrink-0 w-full group block hover:no-underline">
                  <AccountDropdown
                    userRole={role}
                    handleLogout={handleLogout}
                  />
                </div>
              </div>
            </div>
          </Transition>
          <div className="flex-shrink-0 w-14"></div>
        </div>
      </div>
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img src={Logo} />
              </div>
              {renderSidebarNav(role)}
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <div className="flex-shrink-0 w-full group block hover:no-underline">
                <AccountDropdown userRole={role} handleLogout={handleLogout} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
