import React, { useEffect, useState } from "react";
import { PageLoader, Select, Button, DateRangeInput } from "neetoui";
import classnames from "classnames";
import moment from "moment";
import earningsApi from "apis/earnings";
import { EARNING_VIEW_TYPES, INITIAL_FORM_VALUES } from "./constants";
import ViewTable from "./ViewTable";
import EmptyData from "../../Common/EmptyData";

const index = () => {
  const [organizationId, setOrganizationId] = useState(
    localStorage.getItem("userOrganizationId")
  );
  const [totalAmount, setTotalAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [earningsData, setEarningsData] = useState([]);
  const [viewType, setViewType] = useState(null);
  const [filterValues, setFilterValues] = useState(INITIAL_FORM_VALUES);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [referralCount, setReferralCount] = useState(null);

  useEffect(() => {
    fetchEarningsData();
  }, [pageIndex]);

  const fetchEarningsData = async () => {
    const payload = {
      params: {
        page: pageIndex,
        page_size: pageSize,
        scope: "self",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await earningsApi.fetch(organizationId, payload);
      setEarningsData(response.data.referral_earnings);
      setReferralCount(response.data.referrals_count);
      setTotalAmount(response.data.total_amount);
      setTotalCount(response.data.total_count);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);
  const pageIndexEnds = pageIndex * pageSize;
  const pageIndexStarts = pageIndexEnds - pageSize;

  const getNextIndex = function () {
    return pageIndex < totalPages ? pageIndex + 1 : pageIndex;
  };

  const getPrevIndex = function () {
    return pageIndex > 1 ? pageIndex - 1 : pageIndex;
  };

  const nextPage = function () {
    setPageIndex(getNextIndex());
  };

  const previousPage = function () {
    setPageIndex(getPrevIndex());
  };

  const viewEarningsData = async viewType => {
    const payload = {
      params: {
        view: viewType,
        scope: "self",
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await earningsApi.view(organizationId, payload);
      setEarningsData(response.data.referral_earnings);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div className="py-6 pt-16 lg:pt-6">
          <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center">
            <h1 className="flex flex-row justify-center items-center text-2xl font-semibold text-gray-900 mb-4 lg:mb-0">
              <span
                onClick={() => {
                  setViewType(null);
                  fetchEarningsData();
                }}
                className={classnames("cursor-pointer ", {
                  "text-gray-600 hover:text-gray-700": viewType,
                })}
              >
                My Earnings
              </span>
              {viewType && (
                <>
                  <i className="ri-arrow-right-s-line mr-2"></i>
                  <span className="capitalize">{viewType} View</span>
                </>
              )}
            </h1>
            <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-end lg:items-end">
              <DateRangeInput
                className="mb-4 lg:mb-0"
                value={dateRange}
                onChange={selected => {
                  setDateRange(selected);
                  setFilterValues({
                    start_date: selected[0],
                    end_date: selected[1],
                  });
                }}
                format="DD-MM-YYYY"
                minDate={moment("2010-01-01").toDate()}
                maxDate={moment().toDate()}
              />
              <div className="grid grid-cols-2 gap-4 ml-0 lg:ml-3 w-full lg:w-64">
                <Select
                  name="view"
                  placeholder="View"
                  value={EARNING_VIEW_TYPES.find(obj => obj.value === viewType)}
                  onChange={selected => {
                    setViewType(selected.value);
                    viewEarningsData(selected.value);
                  }}
                  options={EARNING_VIEW_TYPES}
                />
                <Button
                  style="primary"
                  icon="ri-filter-line ri-lg"
                  label="Filter"
                  onClick={() => {
                    if (viewType === null) {
                      fetchEarningsData();
                    } else {
                      viewEarningsData(viewType);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-4 lg:px-6 md:px-8">
            <div className="mt-8 shadow rounded-lg bg-white px-4 py-3 border-b border-gray-200 lg:px-6 flex flex-col lg:flex-row justify-between items-center">
              <div className="w-full lg:w-1/2 flex flex-row justify-between items-center mr-0 mb-3 lg:mb-0 lg:mr-4 border-b lg:border-b-0 border-gray-400 lg:border-r lg:border-gray-400 pb-3 lg:pb-0 lg:pr-4 pr-0">
                <h3 className="text-base leading-6 font-medium text-gray-900">
                  Total Amount Earned
                </h3>
                <h3 className="text-lg leading-6 font-semibold text-gray-900">
                  ${totalAmount}
                </h3>
              </div>
              <div className="w-full lg:w-1/2 flex flex-row justify-between items-center">
                <h3 className="text-base leading-6 font-medium text-gray-900">
                  Referrals Count
                </h3>
                <h3 className="text-lg leading-6 font-semibold text-gray-900">
                  {referralCount}
                </h3>
              </div>
            </div>
            <div className="py-4">
              {earningsData.length > 0 ? (
                viewType ? (
                  <ViewTable viewType={viewType} earningsData={earningsData} />
                ) : (
                  <div className="flex flex-col mt-6">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div
                          className={classnames("shadow overflow-hidden", {
                            "sm:rounded-t-lg": totalCount > pageSize,
                            "sm:rounded-lg border-b border-gray-200":
                              totalCount <= pageSize,
                          })}
                        >
                          <table className="mgl-table--no-actions min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Referral Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Phone Number
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Earnings
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {earningsData &&
                                earningsData.map((earnings, index) => (
                                  <tr
                                    className={classnames({
                                      "bg-white": index % 2 == 0,
                                      "bg-gray-100": index % 2 == 1,
                                    })}
                                    key={earnings.id}
                                  >
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                      {earnings.referral_name
                                        ? earnings.referral_name
                                        : "-"}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                      {earnings.referral_phone_number
                                        ? earnings.referral_phone_number
                                        : "-"}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                      ${earnings.amount}
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                      {earnings.created_at}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {totalCount > pageSize && (
                          <nav
                            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 rounded-b-lg shadow"
                            aria-label="Pagination"
                          >
                            <div className="hidden sm:block">
                              <p className="text-sm text-gray-700">
                                Showing{" "}
                                {totalCount < pageIndexEnds ? (
                                  <span className="font-medium">
                                    {pageIndexStarts + 1} - {totalCount} of{" "}
                                    {totalCount}
                                  </span>
                                ) : (
                                  <span className="font-medium">
                                    {pageIndexStarts + 1} - {pageIndexEnds} of{" "}
                                    {totalCount}
                                  </span>
                                )}{" "}
                                results
                              </p>
                            </div>
                            <div className="flex-1 flex justify-between sm:justify-end">
                              <Button
                                style="secondary"
                                className={classnames({
                                  "cursor-not-allowed": pageIndex === 1,
                                })}
                                label="Previous"
                                onClick={() => previousPage()}
                              />
                              <Button
                                style="secondary"
                                className={classnames("ml-3", {
                                  "cursor-not-allowed":
                                    totalPages === pageIndex,
                                })}
                                label="Next"
                                onClick={() => nextPage()}
                              />
                            </div>
                          </nav>
                        )}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <EmptyData message="No Earnings Data Found" />
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
};

export default index;
