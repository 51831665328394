import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { PageLoader, Button, DateRangeInput } from "neetoui";
import moment from "moment";
import dashboardApi from "apis/dashboard";
import EmptyData from "../../Common/EmptyData";

export default function index() {
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({
    start_date: "",
    end_date: "",
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [organizationSummary, setOrganizationSummary] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const payload = {
      params: {
        start_date: filterValues.start_date,
        end_date: filterValues.end_date,
      },
    };
    try {
      setLoading(true);
      const response = await dashboardApi.fetchOrganizationSummary(payload);
      setOrganizationSummary(response.data.organizations);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoader />;
  } else {
    return (
      <>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="py-6 pt-16 lg:pt-6">
            <div className="max-w-7xl mx-auto px-8 flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center">
              <h1 className="text-2xl font-semibold text-gray-900 mb-4 sm:mb-0">
                Organizations Summary
              </h1>
              <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-end lg:items-center">
                <DateRangeInput
                  className="mb-4 lg:mb-0"
                  value={dateRange}
                  onChange={selected => {
                    setDateRange(selected);
                    setFilterValues({
                      start_date: selected[0],
                      end_date: selected[1],
                    });
                  }}
                  format="DD-MM-YYYY"
                  minDate={moment("2010-01-01").toDate()}
                  maxDate={moment().toDate()}
                />
                <Button
                  style="primary"
                  className="ml-0 lg:ml-3"
                  icon="ri-filter-line ri-lg"
                  label="Filter"
                  onClick={() => {
                    loadData();
                  }}
                />
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 lg:px-8 mt-6">
              <div className="py-4">
                {organizationSummary.length > 0 ? (
                  <div className="shadow overflow-auto sm:rounded-lg border-b border-gray-200">
                    <table className="mgl-table--no-actions min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Organization Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Referrals Count
                          </th>
                          <th
                            colSpan="3"
                            scope="col"
                            className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                          >
                            Referrals
                          </th>
                          <th
                            colSpan="2"
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Earnings
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Total Earnings
                          </th>
                        </tr>
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          ></th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          ></th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Pending
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Approved
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Rejected
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Admin
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Caregiver
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {organizationSummary &&
                          organizationSummary.map((organization, index) => (
                            <tr
                              className={classnames({
                                "bg-white": index % 2 == 0,
                                "bg-gray-100": index % 2 == 1,
                              })}
                              key={organization.id}
                            >
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900 capitalize">
                                {organization.name}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-gray-500 text-center">
                                {organization.referrals_count
                                  ? organization.referrals_count
                                  : "0"}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-yellow-500 text-center">
                                {organization.referrals_count_per_status
                                  ?.pending
                                  ? organization.referrals_count_per_status
                                      ?.pending
                                  : "0"}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-green-500 text-center">
                                {organization.referrals_count_per_status
                                  ?.approved
                                  ? organization.referrals_count_per_status
                                      ?.approved
                                  : "0"}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-red-500 text-center">
                                {organization.referrals_count_per_status
                                  ?.rejected
                                  ? organization.referrals_count_per_status
                                      ?.rejected
                                  : "0"}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-indigo-600 text-center">
                                $
                                {organization.earnings_per_role?.admin
                                  ? organization.earnings_per_role?.admin
                                  : "0"}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-indigo-600 text-center">
                                $
                                {organization.earnings_per_role?.caregiver
                                  ? organization.earnings_per_role?.caregiver
                                  : "0"}
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-indigo-600 text-center">
                                {organization.total_earnings ? (
                                  <span>${organization.total_earnings}</span>
                                ) : (
                                  "$0"
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <EmptyData message="No Organization Data Found" />
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
